import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthorizationService } from '../../../_services/authorization.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private authorizationService: AuthorizationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {    
    let loggedIn = this.authorizationService.isUserLoggedIn();
    return loggedIn;
  }
}
