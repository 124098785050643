import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MerchantBannerService } from './service/merchant-banner.service';
import { MerchantBannerComponent } from './component/detail/merchant-banner.component';
import { MerchantBannerListComponent } from './component/list/merchant-banner-list.component';
import { MerchantBannerResolver } from './resolver/merchant-banner.resolver';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    MerchantBannerService,
    MerchantBannerResolver
  ],
  declarations: [
    MerchantBannerComponent,
    MerchantBannerListComponent
  ],
  exports: [
    MerchantBannerComponent,
    MerchantBannerListComponent
  ]
})
export class MerchantBannerModule { }
