enum VHRResponse {

    SUCCESS = "VHR_SUCCESS_00",
    EMAIL_ALREADY_EXIST = "VHR_ERROR_06",
    CODE_ALREADY_EXIST = "DAO_ERROR_35",
    COUNTRY_ALREADY_EXIST = "DAO_ERROR_36",
    INVALID_REQUEST = "VHR_ERROR_97",
    UNKNOWN_ERROR = "VHR_ERROR_98",
    SYSTEM_ERROR = "VHR_ERROR_99",
}

export {VHRResponse};
