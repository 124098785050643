import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../../../util/http/securehttp.service';
// import { SystemConstants } from '../../system/system.constants';
import { Observable } from 'rxjs';
import { ShellComponent } from '../../../shell/shell.component';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class VoucherDenominationService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.API_URL;
  // private headers = SystemConstants.header;
  // private secretKey: string = environment.SECRET_KEY;

  /*
* get active voucher denomincation
*/


  getVoucherDenomination(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + "voucher-denomination/list", { headers })
      .pipe(map((res: any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  /*
* Save Voucher
*/
  saveVoucher(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + `voucher-denomination/save`, body, { headers })
      .pipe(map((res:any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

}