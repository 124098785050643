import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../../../util/http/securehttp.service';
import { Observable } from 'rxjs';
import { ShellComponent } from '../../../shell/shell.component';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CountryService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.API_URL;
  // private secretKey: string = environment.SECRET_KEY;


  /*
* get all active countries
*/
  getActiveCountries(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + "country/list", { headers })
      .pipe(map((res: any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

    /*
* get all active countries
*/
getCategoryList(): Observable<any> {
  const headers = new HttpHeaders().set('Content-Type', 'application/json');
  return this.http.get(this.baseUrl + "category/list", { headers })
    .pipe(map((res: any) => {
      return res;
    })).pipe(catchError((error: any) => {
      console.log(error);
      return error;
    }));
}

  /*
  * update country
  */
  updateCountry(country: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body = JSON.stringify({ "countryVo": country });
    return this.http.post(this.baseUrl + "country/update", body, { headers })
      .pipe(map((res: any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

}