import {Component, OnInit, AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
	moduleId: module.id,
	selector: 'app-footer',
	templateUrl: './footer.html'
})

export class FooterComponent implements OnInit, AfterViewInit{
	constructor(){

	}

		ngOnInit(){

		}

		ngAfterViewInit(){}
}
