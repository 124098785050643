import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { User } from '../../../model/user';
import { UserService } from '../../../../../_services/user.service';
import { Data } from '../../../../../providers/data';
declare var $: any;
import * as _ from 'underscore';
import { FormControl, FormGroup } from '@angular/forms';
import { SearchService } from '../../../../../_services/search.service';

import { VHRResponse } from '../../.././../../util/response/response';
import { ChangePasswordComponent } from '../../../../../shell/shared-components/change-password/change-password.component';

@Component({
  // moduleId: module.id,
  selector: 'user-list',
  templateUrl: './userList.html',
  providers: [UserService]
})
export class UserListComponent implements OnInit, AfterViewInit {

  public users: User[] = new Array<User>();
  public _users: User[] = new Array<User>();
  public user: User;
  public isSuccess: boolean = false;
  public disable: boolean = false;
  public resetPasswordMessage: string;
  public resetPassError: boolean = false;
  public confirmPassword: string;
  public page: number = 0;
  public exportExcel: any;
  public searchModel = {
    firstName: '',
    lastName: '',
    email: '',
    status: '-1'
  };
  public deleteFlag: boolean = false;
  public searchForm: FormGroup;
  public backupList: any[];
  private table;

  @ViewChild('changePassword', { static: false, read: ViewContainerRef })
  dynamicDirective: ViewContainerRef;

  constructor(private router: Router, private route: ActivatedRoute, private data: Data, private searchService: SearchService, private componentFactoryResolver: ComponentFactoryResolver) {
    this.route.data.subscribe(response => {
      if (response.data.payload.code == VHRResponse.SUCCESS) {
        this.users = response.data.payload.responseData.users;
        this._users = response.data.payload.responseData.users;
        this.backupList = this.users;
        if (this.users === null) {
          this.users = new Array();
          this._users = new Array();
        }
      }
    });

    this.searchForm = new FormGroup({
      searchValue: new FormControl(),
      active: new FormControl(true)
    });

    this.users = this.filterArrayByStatus(this.users);

    this.searchForm.get('active').valueChanges.subscribe(value => {
      if (value) {
        this.users = this.filterArrayByStatus(this.users);
        this.reloadTable();
      }
      else {
        this.search();
      }
    });

  }

  ngOnInit() { }
  ngAfterViewInit() {
    this.initializeTable();
  }

  userDetail(user: any, mode: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": mode
      }
    }
    this.data.storage = {
      user: user,
    };
    this.router.navigate(['user', 'detail'], navigationExtras);
  }

  search() {
    let searchValue = this.searchForm.value.searchValue
    this.table.destroy();
    if (searchValue == null || searchValue == '') {
      this.users = this.backupList;
    } else {
      this.users = this.searchService.search(this.backupList, searchValue);
    }
    if (this.searchForm.get('active').value) {
      this.users = this.filterArrayByStatus(this.users);
    }
    this.initializeTable();
  }


  filterArrayByStatus(array) {
    let result = array.filter(element => element.status == 1);
    return result;
  }

  reloadTable() {
    this.table.destroy();
    this.initializeTable();
  }

  openModal(user) {
    let viewContainerRef = this.dynamicDirective;
    if (viewContainerRef != null)
      viewContainerRef.clear();
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(ChangePasswordComponent);
    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<ChangePasswordComponent>componentRef.instance).userData = user;
  }

  initializeTable() {
    setTimeout(() => {
      this.table = $("#users-table").DataTable({
        responsive: true,
        "pageLength": 10,
        stateSave: true,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 },
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
          }, 50);
        }
      });
    }, 50);
  }

}
