import { Injectable } from "@angular/core";
import { CountryService } from "../../country/service/country.service";


@Injectable()
export class CountryListResolver {

    constructor(public service : CountryService ){}

    resolve(){
        return this.service.getActiveCountries();
    }

}