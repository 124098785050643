import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../../../util/http/securehttp.service';
import { interval, Observable, Subscription, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { AuthorizationService } from '../../../_services/authorization.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class LoginService {
  constructor(private http: HttpClient, private authorizationService: AuthorizationService) { }

  private baseUrl = environment.API_URL;
  private refreshTokenInterval: Subscription | any;

  userLogin(body): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body = JSON.stringify({ "loginId": userEmail, "password": password, "merchant": merchant });
    const objBase64 = btoa(JSON.stringify(body));
    let payload = {
      "payload": objBase64
    }
    return this.http.post(this.baseUrl + "user/login", payload, { headers, observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => {
        // let _headers: Headers = res.headers;
        const authToken = res.headers.get('Auth_token');
        console.log(authToken);

        const refreshToken = res.headers.get('Refresh_token');
        console.log(refreshToken);

        // Start the refresh token timer
        this.startRefreshTokenTimer();


        this.authorizationService.setToken(authToken);
        this.authorizationService.setRefreshToken(refreshToken);
        return res.body; // Return response body if needed
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  private startRefreshTokenTimer() {
    if (this.refreshTokenInterval) {
      this.refreshTokenInterval.unsubscribe();
    }

    // Set up a timer to call refresh token API every 2 minutes
    this.refreshTokenInterval = interval(14 * 60 * 1000).pipe(
      switchMap(() => this.refreshToken()) // Call refresh token API
    ).subscribe(
      response => {
        console.log('Token refreshed successfully', response);
      },
      error => {
        console.error('Error refreshing token:', error);
      }
    );
  }

  private refreshToken(): Observable<any> {
    let token = this.authorizationService.getRefreshToken();
    let body = {
      refresh_token: `VHR ${token}`
    };
    let id = localStorage.getItem("userID");
    const objBase64 = btoa(JSON.stringify(body));
    let payload = {
      "payload": objBase64
    };
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')

    return this.http.post<HttpResponse<any>>(
      `${this.baseUrl}user/${id}/refresh-token`,
      payload,
      { headers: headers, observe: 'response' }
    ).pipe(
      map((res: HttpResponse<any>) => {
        console.log(res);
        const authToken = res.headers.get('auth_token');
        console.log(authToken)
        this.authorizationService.setToken(authToken);
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }

  changePassword(username: string, password: string, newPassword: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body = JSON.stringify({ "userEmail": sessionStorage.getItem("userEmailAdmin"), "password": password, "newPassword": newPassword });
    const objBase64 = btoa(JSON.stringify(body));
    let payload = {
      "payload": objBase64
    }
    return this.http.post(this.baseUrl + "user/service/change/password", payload, { headers })
      .pipe(map((res) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }
}
