import { Injectable } from "@angular/core";
import { PaymentMethodService } from "../service/payment-method.service";


@Injectable()
export class PaymentMethodResolver {

    constructor(public service : PaymentMethodService ){}

    resolve(){
        return this.service.getPaymentMethodList();
    }

}