import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullCheck'
})
export class NullCheckPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if(value == undefined || value == null)
      return '';
    else if((value as string).trim() == '')
      return '';
    else if((value as string).trim() == 'null')
      return '';
    return (value as string).trim();
  }

}
