import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../../../_services/authorization.service';
declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'page-not-found',
  templateUrl: './pageNotFound.html'
})
export class PageNotFoundComponent implements OnInit, AfterViewInit {
  constructor(private router: Router, private route: ActivatedRoute, private translate: TranslateService, private authorizationService: AuthorizationService) {
    if(!authorizationService.isUserLoggedIn()) {
      window.location.href="#/login";
    }
    this.route.queryParams.subscribe(param=>{
      //getting queryparam value
    });
  }

  ngOnInit() {   }
  ngAfterViewInit() {
    this.switchLanguage(localStorage.getItem('lan'));
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
