import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../../file-saver/excel.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthorizationService } from '../../../../_services/authorization.service';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';
import { ReportService } from '../../service/report.service';
declare var $: any;

@Component({
  selector: 'transcation-log-report',
  templateUrl: './transcationLogReport.html'
})
export class TranscationLogReportComponent implements OnInit, AfterViewInit {

  transcationlogs: any[];
  searchForm: FormGroup;

  table: any;
  backupList: any;
  user: any;
  public merchantList: any;

  constructor(private route: ActivatedRoute, private excelService: ExcelService, private authService: AuthorizationService,
    public ngxloader: NgxUiLoaderService, private reportService: ReportService) {

    this.searchForm = new FormGroup({
      createdDateFrom: new FormControl(''),
      createdDateTo: new FormControl(''),
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    setTimeout(() => {
      $('select').formSelect();
      $('.datepicker').datepicker({ format: 'mm/dd/yyyy', });
    },
      50);
    this.initializeTable();
  }


  exportExcel() {
    let transcations = new Array();
    for (let voucher of this.transcationlogs) {
      let voucherObj = {
        // "Transcation ID": voucher.transcationId,
        "Txn Ref No:": voucher.txnRefNo,
        "Account No:": voucher.accountNo,
        // "Email Address": voucher.emailAddress,
        "Voucher ID": voucher.merchantVoucherId,
        // "BPaypoint transcation ID": voucher.paypointTranscationId,
        "Paypoint Operation ID": voucher.paypointOperationId,
        "Alfalah Description": voucher.alfa,
        "Paypoint Description": voucher.paypointResponse,
        "Message": voucher.message,
        "Created Date": voucher.createdDate,
      }
      transcations.push(voucherObj);
    }
    this.excelService.exportAsExcelFile(transcations, "Transcation_Log_Report");
  }

  search() {
    this.searchForm.controls.createdDateFrom.setValue($("#createdDateFrom").val());
    this.searchForm.controls.createdDateTo.setValue($("#createdDateTo").val());
    let searchFormValues = this.searchForm.getRawValue();
    this.ngxloader.start();
    this.reportService.transcationLogReport(searchFormValues).subscribe(res => {
      if (res.payload.code === "VHR_SUCCESS_00") {
        this.transcationlogs = res.payload.responseData.data;
        this.backupList = this.transcationlogs;
        this.table.destroy();
        this.initializeTable();
        this.ngxloader.stop();
      }
    }, error => {
      console.error("Report Fetch Failed : " + error)
    });
    this.ngxloader.stop();
  }

  initializeTable() {
    setTimeout(() => {
      this.table = $("#voucher-list").DataTable({
        responsive: true,
        "pageLength": 100,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 },
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
            $(".dropdown-trigger").dropdown({ constrainWidth: false });
          }, 50);
        }
      });
    }, 50);
  }

  filter() { }

}
