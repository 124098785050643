import { Injectable } from "@angular/core";
import { MerchantService } from "../../merchant/service/merchant.service";


@Injectable()
export class TranscationReportResolver {

    constructor(public service : MerchantService ){}

    resolve(){
        return this.service.getActiveMerchants();
    }

}