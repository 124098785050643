
import { Component, OnInit, AfterViewInit, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import { AuthorizationService } from '../../_services/authorization.service';
import { CookieService } from '../../_services/cookie.service';
import { ChangePasswordComponent } from '../shared-components/change-password/change-password.component';

declare var $:any;

@Component({
  moduleId: module.id,
  selector: 'app-header',
  templateUrl: './header.html',
  providers: [AuthorizationService]
})

export class HeaderComponent implements OnInit, AfterViewInit {

  public userName: string;
  public lan: string;

  @ViewChild('changePassword', {static: false,read: ViewContainerRef})
  dynamicDirective :ViewContainerRef;

  constructor(private router: Router, private route: ActivatedRoute,
    private componentFactoryResolver:ComponentFactoryResolver,
    private authorizationService: AuthorizationService, private ngxService: NgxUiLoaderService, private scriptLoaderService: ScriptLoaderService, private cookieService : CookieService) {}

  ngOnInit() {
    this.userName = localStorage.getItem('firstName');
  }

  ngAfterViewInit() {}

  logout() {
    this.scriptLoaderService.clear('plugins');
    this.authorizationService.logout();
  }

  isPermitted(code: string) {
    return this.authorizationService.hasPermission(code);
  }

  openPasswordModal() {
    let user = this.authorizationService.getLoggedInUser();
    let viewContainerRef = this.dynamicDirective;
    if(viewContainerRef != null)
    viewContainerRef.clear();
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(ChangePasswordComponent);
    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<ChangePasswordComponent>componentRef.instance).userData = user;
  }
}
