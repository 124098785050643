import { Component } from '@angular/core'
import { AuthorizationService } from '../../_services/authorization.service';

@Component({
  moduleId: module.id,
  selector: 'app-menu',
  templateUrl: './menu.html'
})

export class MenuComponent {
  public link: string;
  public userName: string = "Nand Khatri";
  public lan: string;

  constructor(private authorizationService: AuthorizationService) {
  }

  isPermitted(code: string) {
    return this.authorizationService.hasPermission(code);
  }

  isPermitterOnAny(codes: string[]){
    let check = false;
    for (const code of codes) {
      check = check || this.isPermitted(code);
    }
    return check;
  }
}
