import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { RoleService } from "../../../_services/role.service";

@Injectable()
export class RoleListResolver implements Resolve<any> {

  constructor(private roleService: RoleService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.roleService.getRoles();
  }

}