import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html'
})
export class ConversationComponent implements AfterViewInit {

  @Input() id: any;

  constructor() { }

  ngAfterViewInit(): void {
    this.openModal();
  }


  openModal() {
    setTimeout(() => {
      $('.modal.conversation').modal({
        dismissible: false,
        endingTop: '25%',
      }),
        $('.modal.conversation').modal('open');
    }, 50);
  }

  closeModal() {
    setTimeout(() => {
      $('.modal.conversation').modal('close');
    }, 50);
  }
}
