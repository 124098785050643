import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Data } from '../../../../../providers/data';
import { Role } from '../../../model/role';
import { RoleService } from '../../../../../_services/role.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SearchService } from '../../../../../_services/search.service';
import { VHRResponse } from '../../../../../util/response/response';
declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'role-list',
  templateUrl: './roleList.html',
  providers: [RoleService]
})
export class RoleListComponent implements OnInit, AfterViewInit {

  public roles: Role[] = new Array<Role>();
  public role: Role;
  public backupList: any[]
  private table;
  public searchForm: FormGroup

  public page: number = 0;
  public deleteFlag: boolean = false;
  public exportExcel: any;

  constructor(private roleService: RoleService, private router: Router, private data: Data, private route: ActivatedRoute, private searchService: SearchService) {
    this.route.data.subscribe(response => {

      if (response.data.payload.code == VHRResponse.SUCCESS) {
        this.roles = response.data.payload.responseData.roles;
        this.backupList = this.roles;
        if (this.roles === null) {
          this.roles = new Array();
        }
      }
    });

    this.searchForm = new FormGroup({
      searchValue: new FormControl(),
      active: new FormControl(true)
    });

    this.roles = this.filterArrayByStatus(this.roles);

    this.searchForm.get('active').valueChanges.subscribe(value => {
      if (value) {
        this.roles = this.filterArrayByStatus(this.roles);
        this.reloadTable();
      }
      else {
        this.search();
      }
    });

  }

  ngOnInit() { }
  ngAfterViewInit() {
    this.reloadTable();
  }

  roleDetail(role: any, mode: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": mode
      }
    }
    this.data.storage = {
      role: role
    };
    this.router.navigate(['role', 'detail'], navigationExtras);
  }

  deleteUserModalShow(role: any) {
    this.role = role;
    console.log(role);

    $("#delete-user-modal").modal({
      backdrop: 'static',
      keyboard: false
    });
    $("#successDeleteUser").hide();
    $("#deleteBtn").show();
  }

  deleteUser() {
    let rId = this.role.id;
    $("#deleteBtn").hide();
    $("#delete-user-modal").hide();
    this.roleService.deleteRole(rId).subscribe((res) => {
      this.goBackAfterDelete();
      let result = res.responseData;
      if (res != null && res.responseData != null) { }
    }, error => {
      console.log(error);
    })
  }

  goBackAfterDelete() {
    if (this.deleteFlag) {
      this.deleteFlag = false;
      window.location.reload();
    }
  }

  search() {
    let searchValue = this.searchForm.value.searchValue;
    if (searchValue == null || searchValue == '') {
      this.roles = this.backupList;
    }
    else {
      this.roles = this.searchService.search(this.backupList, searchValue);
    }

    if (this.searchForm.get('active').value) {
      this.roles = this.filterArrayByStatus(this.roles);
    }
    this.reloadTable();
  }

  filterArrayByStatus(array) {
    let result = array.filter(element => element.status == 1);
    return result;
  }

  reloadTable() {
    try {
      this.table.destroy();
    } catch (Error) { }
    setTimeout(() => {
      this.table = $("#roles-table").DataTable({
        responsive: true,
        "pageLength": 100,
        stateSave: true,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 },
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
          }, 50);
        }
      });
    }, 50);
  }

}
