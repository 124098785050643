import { Injectable } from "@angular/core";
import { MerchantRequestService } from "../service/merchant-request.service";




@Injectable()
export class MerchantRequestListResolver {

    constructor(public service : MerchantRequestService ){}

    resolve(){
        return this.service.getRequestList();
    }

}