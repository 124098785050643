import { Injectable } from '@angular/core';
import { HttpService } from '../../../util/http/securehttp.service';
import { environment } from '../../../../environments/environment';

import { map, catchError } from 'rxjs/operators';
import { Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.API_URL;
  //   private headers = SystemConstants.header;
  // private secretKey: string = environment.SECRET_KEY;


  /*
  * get created voucher report
  */

  createdVoucherReport(searchForm: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + `report/created-voucher`, searchForm, { headers })
      .pipe(map((res:any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  transcationReport(searchForm: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + `report/transcation`, searchForm, { headers })
      .pipe(map((res:any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  transcationLogReport(searchForm: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + `report/transcation/logs`, searchForm, { headers })
      .pipe(map((res:any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }


  merchantReport(searchForm: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + `report/merchant`, searchForm, { headers })
      .pipe(map((res:any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

}