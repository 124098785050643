import { Injectable } from "@angular/core";

@Injectable({
    providedIn : 'root'
})
export class SearchService{
    constructor(){}

    public searchResults = new Array();

    search(list, filter){
        this.searchResults = new Array();
        filter = String(filter).toLowerCase();
        let length = list.length;        
        for(let i=0; i<length; i++){
            let element = list[i];
            let isPresent = false;
            for(var key in element){
                if(String(element[key]).toLowerCase().search(filter) != -1){
                    isPresent = true;
                }
            }
            if(isPresent)
                this.searchResults.push(element);
        }
        return this.searchResults;
    }
}