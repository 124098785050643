import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from '../../auth/authorization/guard/authorization.guard';
import { LoggedInGuard } from '../../auth/login/guard/login.guard';
import { RoleDetailComponent } from './component/manage-role/detail/role-detail.component';
import { RoleListComponent } from './component/manage-role/list/role-list.component';
import { UserDetailComponent } from './component/manage-user/detail/user-detail.component';
import { UserListComponent } from './component/manage-user/list/user-list.component';
import { UserDetailResolver } from './resolver/user-detail.resolver';
import { UserListResolver } from './resolver/user-list.resolver';
import { RoleListResolver } from './resolver/role-list.resolver';
import { RoleDetailResolver } from './resolver/role-detail.resolver';

const routes: Routes = [
  {
    path: "user/list",
    component: UserListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'UM-U'},
    resolve: {
      data: UserListResolver
    }
  },
  {
    path: "user/detail",
    component: UserDetailComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'UM-U' },
    resolve: {
      data: UserDetailResolver
    }
  },
  {
    path: "role/detail",
    component: RoleDetailComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'UM-R'},
    resolve: {
      data: RoleDetailResolver
    }
  },
  {
    path: "role/list",
    component: RoleListComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'UM-R'},
    resolve: {
      data: RoleListResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule { }
