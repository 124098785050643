import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DashboardComponent } from './component/dashboard.component';
import { PageNotFoundComponent } from './component/page-not-found.component';
import { ChartsModule } from 'ng2-charts';
import { LineChartComponent } from './charts/line-chart';
import { DashboardService } from '../dashboard/service/dashboard.service';
import { DashboardResolver } from './resolver/dashboard.resolver';
import { MerchantVoucherService } from '../merchant-voucher/service/merchant-voucher.service';
import { MerchantService } from '../merchant/service/merchant.service';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	imports: [
		CommonModule,
		ChartsModule,
		FormsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		DashboardService,
		DashboardResolver,
		MerchantVoucherService,
		MerchantService
	  ],

	declarations: [
		DashboardComponent,
		PageNotFoundComponent,
		LineChartComponent
	],
	exports: [
		DashboardComponent,
		PageNotFoundComponent
	]
})
export class DashboardeModule {

}
