import { Injectable } from '@angular/core';
import { HttpService } from '../../../util/http/securehttp.service';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient,) { }

  private baseUrl = environment.API_URL;

  getDashboardCounts() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + `dashboard/counts`, { headers })
      .pipe(map((res) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }


  /*
* get all vouchers payment
*/
  getVouchersPayment(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + "voucherTransaction/list", { headers })
      .pipe(map((res: any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

}
