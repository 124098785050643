import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { AuthorizationService } from "../../../_services/authorization.service";
import { DashboardService } from "../../dashboard/service/dashboard.service";

@Injectable()
export class DashboardResolver implements Resolve<any>{


    constructor(private dashboardService : DashboardService, private authorizationService: AuthorizationService){ }

    // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     return this.dashboardService.getDashboardCounts();
    // }
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requests = [this.dashboardService.getDashboardCounts(), this.dashboardService.getVouchersPayment()];
        return forkJoin(requests).pipe(map((responses) => {
            let response = {
                counts: responses[0],
                voucherPayment: responses[1]
            }
            return response;
        }))
    }

}