import { Component } from "@angular/core";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, Label } from "ng2-charts";

@Component({
    selector: 'line-chart',
    templateUrl: './line-chart.html',
})
export class LineChartComponent {

    constructor(){}

    public lineChartData: ChartDataSets[] = [
        { data: [65, 59, 80, 59, 56, 55, 40] },
      ];
      public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      public lineChartOptions: ChartOptions = {
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              fontColor: "white",
              min:20,
              max: 80,
              // fontSize: 18,
              stepSize: 5,
              beginAtZero: false
            }
          }],
          xAxes: [{
            ticks: {
              fontColor: "white",
              // fontSize: 14,
              stepSize: 1,
              beginAtZero: false
            }
          }]
        },
        legend: {
          display: false,
        }
      };
      public lineChartColors: Color[] = [
        {
          borderColor: 'white',
          backgroundColor: 'rgba(105, 229, 236, 0.5)',
          // backgroundColor: 'rgba(21, 96, 147, 0.77)',
        },
      ];
      public lineChartLegend = true;
      public lineChartType = 'line';
      public lineChartPlugins = [];

}