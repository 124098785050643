import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PaymentMethodService } from '../payment-method/service/payment-method.service';
import { PaymentMethodListComponent } from './component/list/payment-method-list.component';
import { PaymentMethodComponent } from '../payment-method/component/detail/payment-method.component';
import { PaymentMethodResolver } from '../payment-method/resolver/payment-method.resolver';
import { PaymentMethodDetailResolver } from '../payment-method/resolver/payment-method-detail.resolver';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    PaymentMethodService,
    PaymentMethodResolver,
    PaymentMethodDetailResolver
    
  ],
  declarations: [
    PaymentMethodListComponent,
    PaymentMethodComponent
  ],
  exports: [
    PaymentMethodListComponent,
    PaymentMethodComponent
  ]
})
export class PaymentMethodModule { }
