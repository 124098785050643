import { Injectable } from "@angular/core";
import { CountryService } from "../../country/service/country.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";



@Injectable()
export class MerchantDetailResolver {

    constructor(public service: CountryService) { }

    // resolve(){
    //     return this.service.getActiveCountries();
    // }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requests = [this.service.getActiveCountries(), this.service.getCategoryList()];
        return forkJoin(requests).pipe(map((responses) => {
            let response = {
                country: responses[0],
                category: responses[1]
            }
            return response;
        }))
    }

}