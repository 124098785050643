import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MerchantRequestListResolver } from './resolver/merchant-request-list.resolver';
import { MerchantRequestService } from './service/merchant-request.service';
import { MerchantRequestComponent } from './component/detail/merchant-request.component';
import { MerchantRequestListComponent } from './component/list/merchant-request-list.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    MerchantRequestListResolver,
    MerchantRequestService
  ],
  declarations: [
   MerchantRequestComponent,
   MerchantRequestListComponent
  ],
  exports: [
    MerchantRequestComponent,
   MerchantRequestListComponent
  ]
})
export class MerchantRequestModule { }
