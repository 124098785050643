import { Component, ViewEncapsulation, AfterViewInit } from '@angular/core';

declare var $: any;

import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { AuthorizationService } from '../_services/authorization.service';

@Component({
  moduleId: module.id,
  selector: 'shell',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './shell.html',
  providers:[AuthorizationService]
})

export class ShellComponent implements AfterViewInit{

  loading: boolean = true;
  public isLogin: boolean = false;

  constructor(private router: Router, private authorizationService: AuthorizationService) {
    if(!this.isLoggedIn()) {
      window.location.href = "#/login";
    }
    this.router.events.subscribe(x => this.navigationInterceptor(x))
  }
  ngAfterViewInit() {
 
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  isLoggedIn() {
    return this.authorizationService.isUserLoggedIn();
  }

}
