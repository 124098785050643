import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { CountryService } from "../../country/service/country.service";
import { CurrencyService } from '../../currency/service/currency.service';
import { MerchantService } from "../../merchant/service/merchant.service";



@Injectable()
export class MerchantVoucherResolver {

    constructor(private currencyService: CurrencyService, private merchantService: MerchantService, public service: CountryService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requests = [this.merchantService.getActiveMerchants(), this.currencyService.getActiveCurrencies(), this.service.getCategoryList()];
        return forkJoin(requests).pipe(map((responses) => {
            let response = {
                merchant: responses[0],
                currency: responses[1],
                category: responses[2]
            }
            return response;
        }))
    }

}