import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MerchantService } from '../merchant/service/merchant.service';
import { MerchantListComponent } from './component/list/merchant-list.component';
import { MerchantComponent } from '../merchant/component/detail/merchant.component';
import { MerchantResolver } from '../merchant/resolver/merchant.resolver';
import { MerchantDetailResolver } from '../merchant/resolver/merchant-detail.resolver';






@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    MerchantService,
    MerchantResolver,
    MerchantDetailResolver
  ],
  declarations: [
    MerchantListComponent,
    MerchantComponent,
  ],
  exports: [
    MerchantListComponent,
    MerchantComponent
  ]
})
export class MerchantModule { }
