import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Data } from '../providers/data';
import { routing } from './shell.routing';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ShellComponent } from './shell.component';
import { MenuComponent } from '../shell/menu/menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggedInGuard } from '../auth/login/guard/login.guard';
import { AuthorizationGuard } from '../auth/authorization/guard/authorization.guard';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { UserRoutingModule } from '../module/user/user.routing';
import { ConversationComponent } from './shared-components/conversation/conversation.component';
import { ChangePasswordComponent } from './shared-components/change-password/change-password.component';
import { ReportRoutingModule } from '../module/reports/report.routing';

let config: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#045481',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'cube-grid',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40,40,40,0.3)',
  pbColor: '#045481',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HttpClientModule,
    ReportRoutingModule,
    NgxUiLoaderModule.forRoot(config),
    NgxUiLoaderRouterModule,
    UserRoutingModule,
  ],

  declarations: [
    ShellComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ConversationComponent,
    ChangePasswordComponent
  ],

  providers: [
    Data,
    DatePipe,
    LoggedInGuard,
    AuthorizationGuard
  ],
  exports: [
    ShellComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    ConversationComponent,
    ChangePasswordComponent
  ]
})

export class ShellModule {
}
