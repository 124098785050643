import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Data } from '../.../../../../providers/data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PaypointFetchDataService } from '../service/paypoint-fetch-data.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'fetch-country',
  templateUrl: './fetchCountry.html',
  providers: [PaypointFetchDataService]
})
export class FetchCountryComponent implements OnInit {
  public mode: any;
  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;
  submitted = false;
  public request: any
  countryForm : FormGroup;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: Data, private route: ActivatedRoute, 
    private changeDetector: ChangeDetectorRef, public ngxloader: NgxUiLoaderService, private paypoint: PaypointFetchDataService) {
    
    this.countryForm = new FormGroup({
      country : new FormControl('0'),
      companyId : new FormControl('0'),
    })

  }

  ngOnInit() {}

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
  
  }

  onSubmit() {
  let countryFormValues = this.countryForm.getRawValue();
  this.ngxloader.start();
  this.paypoint.fetchCountries(countryFormValues).subscribe(res => {
    if (res.code === "VHR_SUCCESS_00") {
      
      this.ngxloader.stop();
    }
  },error =>{
    console.error("Report Fetch Failed : "+error)
  });
  this.ngxloader.stop();
}

}
