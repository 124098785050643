import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as _ from 'underscore';
import { CountryService } from '../../service/country.service';
import { Data } from '../../../../providers/data';
import { Country } from '../../model/country';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';

declare var $: any;

@Component({
  selector: 'country-list',
  templateUrl: './countryList.html',
  styleUrls: [],
  providers: [CountryService]
})
export class CountryListComponent implements OnInit, AfterViewInit {

  public countries: Country[] = new Array<Country>();
  public Country: Country;
  public countryList: any;
  private table;


  constructor(private countryService: CountryService, private router: Router, private data: Data,
    private ngxService: NgxUiLoaderService, public route: ActivatedRoute) {

    this.route.data.subscribe(response => {
      if (response.data.payload.code === VHRResponse.SUCCESS) {
        this.countryList = response.data.payload.responseData.countries;
      }
    });
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.loadTable();
    setTimeout(() => $('select').formSelect(), 50);
  }

  add() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": "Add"
      }
    }
    this.router.navigate(['country'], navigationExtras);
  }

  editCountryDetail(country: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": "Edit"
      }
    }
    this.data.storage = {
      country: country,
    };
    this.router.navigate(['country'], navigationExtras);
  }

  viewUserDetail(country: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": "View",
      }
    }
    this.data.storage = {
      country: country,
    };
    this.router.navigate(['country'], navigationExtras);
  }

  loadTable() {
    setTimeout(() => {
      this.table = $("#country-table").DataTable({
        responsive: true,
        "pageLength": 10,
        fixedColumns: true,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 }
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
          }, 50);
        }
      });
    }, 50);
  }

}
