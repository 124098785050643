import { NgModule } from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserService } from '../../_services/user.service';
import { RoleListComponent } from './component/manage-role/list/role-list.component';
import { RoleService } from '../../_services/role.service';
import { UserRoutingModule } from './user.routing';
import { UserDetailComponent } from './component/manage-user/detail/user-detail.component';
import { UserListComponent } from './component/manage-user/list/user-list.component';
import { RoleDetailComponent } from './component/manage-role/detail/role-detail.component';
import { UserListResolver } from './resolver/user-list.resolver';
import { UserDetailResolver } from './resolver/user-detail.resolver';
import { RoleListResolver } from './resolver/role-list.resolver';
import { RoleDetailResolver } from './resolver/role-detail.resolver';
import { PipeModule } from '../../_pipes/pipe.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule ({
  imports: [
   		CommonModule,
      HttpClientModule,
	    TranslateModule.forRoot({
	      loader: {
	        provide: TranslateLoader,
	        useFactory: HttpLoaderFactory,
	        deps: [HttpClient]
	      }
	    }),
      FormsModule,
      ReactiveFormsModule,
      UserRoutingModule,
      PipeModule
    ],
  providers: [
    UserService,
    RoleService,
    UserListResolver,
    UserDetailResolver,
    RoleListResolver,
    RoleDetailResolver,
  ],
  declarations: [
    UserDetailComponent,
    UserListComponent,
    RoleDetailComponent,
    RoleListComponent,
  ],
  exports: [],
  entryComponents : []
})
export class UserModule {}
