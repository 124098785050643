import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { noWhiteSpaceValidator } from "../../validators/check-whitespace.directive";
import { UserService } from "../../../_services/user.service";
import { VHRResponse } from '../../../util/response/response';
import { AuthorizationService } from '../../../_services/authorization.service';

declare var $: any;

@Component({
  selector: 'change-password',
  templateUrl: './changePassword.html'
})

export class ChangePasswordComponent implements AfterViewInit, OnInit {

  @Input() userData: any;
  public form: FormGroup;
  public submitted = false;

  constructor(private userService: UserService, private ngxLoader: NgxUiLoaderService, private authService: AuthorizationService) {
    this.form = new FormGroup({
      userEmail: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(8), noWhiteSpaceValidator]),
      confirmPassword: new FormControl('', Validators.required)
    })
    this.form.controls.confirmPassword.valueChanges.subscribe(value => {
      if (value != this.form.get("password").value) {
        this.form.get("confirmPassword").setErrors({ notMatched: true })
      } else {
        this.form.get("confirmPassword").setErrors(null)
      }
    });
  }

  ngOnInit() {
    this.form.get('userEmail').setValue(this.userData['email']);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('.modal.password').modal({
        dismissible: false,
        endingTop: '15%',
      }),
        $('.modal.password').modal('open');
    }, 50);
  }

  closeModal() {
    setTimeout(() => {
      $('.modal.password').modal('close');
    }, 50);
  }

  submit() {
    this.submitted = true;
    if (this.form.get('password').value != this.form.get('confirmPassword').value) {
      this.form.get("confirmPassword").setErrors({ notMatched: true });
    } else {
      this.form.get("confirmPassword").setErrors(null);
    }
    if (this.form.invalid)
      return;

    let payload = {
      email: this.form.get('userEmail').value,
      newPassword: this.form.get('password').value
    }
    this.ngxLoader.start();
    this.userService.changePassword(payload).subscribe(response => {
      if (response.code == VHRResponse.SUCCESS) {
        this.closeModal();
        this.ngxLoader.stop();
      }
    });
  }

}
