import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private readonly AES_ALGORITHM = 'AES-CBC';
  private readonly AES_KEY_SIZE = 256; // Key size in bits
  private readonly IV_LENGTH = 16; // AES block size in bytes

  constructor() { }

  // Method to encrypt plaintext using AES
  async encrypt(plaintext: string, key: string): Promise<string> {
    const keyBytes = new TextEncoder().encode(key);
    const cryptoKey = await this.importKey(keyBytes);
    const iv = crypto.getRandomValues(new Uint8Array(this.IV_LENGTH));
    const plaintextBytes = new TextEncoder().encode(plaintext);

    const encryptedData = await crypto.subtle.encrypt({
      name: this.AES_ALGORITHM,
      iv: iv
    }, cryptoKey, plaintextBytes);

    const ivStr = this.arrayBufferToBase64(iv);
    const encryptedStr = this.arrayBufferToBase64(new Uint8Array(encryptedData));
    return `${ivStr}:${encryptedStr}`;
  }

  // Method to decrypt ciphertext using AES
  async decrypt(ciphertext: string, key: string): Promise<string> {
    const parts = ciphertext.split(':');
    const iv = this.base64ToArrayBuffer(parts[0]);
    const encryptedBytes = this.base64ToArrayBuffer(parts[1]);

    const keyBytes = new TextEncoder().encode(key);
    const cryptoKey = await this.importKey(keyBytes);

    const decryptedData = await crypto.subtle.decrypt({
      name: this.AES_ALGORITHM,
      iv: iv
    }, cryptoKey, encryptedBytes);

    return new TextDecoder().decode(decryptedData);
  }

  // Helper method to convert ArrayBuffer to Base64
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(buffer))));
  }

  // Helper method to convert Base64 to ArrayBuffer
  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // Helper method to import a raw key for AES
  private async importKey(keyBytes: Uint8Array): Promise<CryptoKey> {
    const algorithm: AesKeyAlgorithm = {
      name: this.AES_ALGORITHM,
      length: 0
    };
    return await crypto.subtle.importKey(
      'raw',
      keyBytes,
      algorithm,
      false,
      ['encrypt', 'decrypt']
    );
  }
}
