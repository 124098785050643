import { Injectable } from "@angular/core";
import { CountryService } from "../../country/service/country.service";
import { MerchantService } from "../service/merchant.service";


@Injectable()
export class MerchantResolver {

    constructor(public service : MerchantService ){}

    resolve(){
        return this.service.getActiveMerchants();
    }

}