import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MerchantVoucherService } from '../merchant-voucher/service/merchant-voucher.service';
import { MerchantVoucherListComponent } from '../merchant-voucher/component/list/merchant-voucher-list.component';
import { MerchantVoucherComponent } from '../merchant-voucher/component/detail/merchant-voucher.component';
import { MerchantVoucherResolver } from '../merchant-voucher/resolver/merchant-voucher.resolver';
import { MerchantVoucherListResolver } from './resolver/merchant-voucher-list.resolver';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    MerchantVoucherService,
    MerchantVoucherResolver,
    MerchantVoucherListResolver
  ],
  declarations: [
    MerchantVoucherListComponent,
    MerchantVoucherComponent
  ],
  exports: [
    MerchantVoucherListComponent,
    MerchantVoucherComponent
  ]
})
export class MerchantVoucherModule { }
