import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../../../util/http/securehttp.service';
// import { SystemConstants } from '../../system/system.constants';
import { Observable } from 'rxjs';
import { ShellComponent } from '../../../shell/shell.component';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MerchantBannerService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.API_URL;
  // private headers = SystemConstants.header;
  // private secretKey: string = environment.SECRET_KEY;

  /*
* get all active merchant vouchers
*/

  /*
* Save Merchant
*/
  saveBanner(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + `homePage/banner/upload`, body, { headers })
      .pipe(map((res) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

}