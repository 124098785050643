import {Injectable} from '@angular/core';
import {Http, XHRBackend, RequestOptions, Request, RequestOptionsArgs, Response, Headers} from '@angular/http';
import {Observable} from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';;
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import { AuthorizationService } from '../../_services/authorization.service';

@Injectable()
export class HttpService extends Http {
  static isWorking: boolean = false;

  constructor (backend: XHRBackend, options: RequestOptions, private authorizationService: AuthorizationService) {
    super(backend, options);
  }

  request(url: string|Request, options?: RequestOptionsArgs): Observable<Response> {
    let token = this.authorizationService.getToken();
    if(token != undefined && token != null){
      if (typeof url === 'string') { // meaning we have to add the token to the options, not in url
        if (!options) {
          // let's make option object
          options = {headers: new Headers()};
        }
        options.headers.set('Authorization', `VHR ${token}`);
      } else {
      // we have to add the token to the url object
        url.headers.set('Authorization', `VHR ${token}`);
      }
    }
    return super.request(url, options).pipe(catchError(this.catchAuthError(this, url, options)));
  }

  private catchAuthError (self: HttpService, url: string|Request, options?: RequestOptionsArgs) {
    return (res: Response) => {
      if(res.status === 401 && !HttpService.isWorking && url !== null) {
        let headers = new Headers();
        let refreshToken = 'VHR ' + this.authorizationService.getRefreshToken();
        headers.append('Content-Type', 'application/json');
        headers.append('refresh_token', refreshToken);
        HttpService.isWorking = true;
        return this.get(environment.API_URL + `user/${this.authorizationService.getLoggedInUser().id}/refresh-token`,
            { headers }
          )
          .pipe(map((res) => {
            if (res.headers.get("auth_token")) {
              var token = res.headers.get('auth_token');
              this.authorizationService.setToken(token);
              HttpService.isWorking = false;
              return null;
            }
            HttpService.isWorking = false;

            return Observable.throw(res);

          }))
          .pipe(mergeMap(x => self.request(url, options)))
          .pipe(catchError((error: Response) => {
            HttpService.isWorking = false;
            if (res.status === 401 || res.status === 403 ) {
              this.authorizationService.logout();
            }
            return throwError(error);
          }));
      }

      return throwError(res);
    };
  }
}
