import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response';
import { PaymentMethodService } from '../../service/payment-method.service';
import { DomSanitizer } from '@angular/platform-browser';

declare var M: any;
declare var $: any;

@Component({
  selector: 'payment-method-detail',
  templateUrl: './paymentMethod.html',
  providers: [PaymentMethodService]
})

export class PaymentMethodComponent implements OnInit {
  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;
  submitted = false;
  public countryList: any;
  public mode: any;
  public paymentMethod: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: Data, private route: ActivatedRoute, private service: PaymentMethodService,
    private changeDetector: ChangeDetectorRef, private sanitizer: DomSanitizer) {

    this.route.data.subscribe(response => {
      if (response.data.payload.code === VHRResponse.SUCCESS) {
        this.countryList = response.data.payload.responseData.countries;
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          // console.log(this.data);
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.paymentMethod = this.data.storage.paymentMethod;
            // console.log(this.paymentMethod);
          } else {
            window.location.href = "#/payment-method/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.paymentMethodForm.disable();
          } else {
            window.location.href = "#/payment-method/list";
            return;
          }
        } else {
          return;
        }
        this.paymentMethod = this.data.storage.paymentMethod;
      }
    });

  }

  paymentMethodForm = this.formBuilder.group({
    id: [''],
    paymentMethodName: ['', Validators.required],
    code: ['', Validators.required],
    countryId: ['', Validators.required],
    imageURL: [''],
    imageUrlStr: [''],
    imageFileName: [''],
    isActive: [true],
  });

  get f() { return this.paymentMethodForm.controls; }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('select').formSelect();
    }, 100);

    if (this.mode != 'Add') {
      this.paymentMethodForm.patchValue(this.paymentMethod);
      M.updateTextFields();
      setTimeout(() => $('select').formSelect(), 50);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.paymentMethodForm.invalid) {
      return;
    }
    // console.log(this.paymentMethodForm.getRawValue());
    let body = this.paymentMethodForm.getRawValue()
    this.service.savePaymentMethod(body).subscribe(response => {
      if (response.payload.code == VHRResponse.SUCCESS) {
        this.isSuccess = true;
        this.message = this.mode == 'Add' ? "SUCCESS!  Created SuccessFully" : "SUCCESS!  Updated SuccessFully";
        setTimeout(() => {
          this.router.navigate(['payment-method', 'list']);
        }, 1500)
      }
      else if (response.payload.code == VHRResponse.UNKNOWN_ERROR) {
        this.isError = true;
      } else {
        this.isError = true;
      }
    });
  }

  addImage(filename, imageBase64) {
    this.paymentMethodForm.controls.imageURL = new FormGroup({
      imageBase64: new FormControl(imageBase64),
      filename: new FormControl(filename),
    });
  }

  handleImageUpload(files) {
    const reader = new FileReader();
    let filename = files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      let iamgeGrp = new FormGroup({
        imageBase64: new FormControl(reader.result),
        filename: new FormControl(filename),
      });
      this.paymentMethodForm.get('imageUrlStr').setValue(iamgeGrp.value.imageBase64);
      this.paymentMethodForm.get('imageFileName').setValue(iamgeGrp.value.filename);
    }
  }

  getSanitizedImageBase64() {
    //   let bannerImageUrl = this.merchantForm.controls.bannerImageUrl;
    let imageBase64 = this.paymentMethodForm.get('imageUrlStr').value;
    imageBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(imageBase64);
    return imageBase64;
  }

  openImageInNewTab() {

    let url = this.getSanitizedImageBase64();
    url = url.changingThisBreaksApplicationSecurity;
    let img = '<img src="' + url + '">';
    let w = window.open();
    w.document.write(img);
  }

}
