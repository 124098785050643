import { ReportRoutingModule } from './report.routing';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShellModule } from '../../shell/shell.module';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { CreatedVoucherReportComponent } from './component/created-voucher/created-voucher-report.component';
import { PurchasedVoucherReportComponent } from './component/purchased-voucher/purchased-voucher-report.component';
import { CreatedVoucherReportResolver } from './resolver/created-voucher.resolver';
import { ReportService } from './service/report.service';
import { TrancationReportComponent } from './component/transcation/transcation-report.component';
import { TranscationReportResolver } from './resolver/transcation.resolver';
import { TranscationLogReportComponent } from '../reports/component/transcation-log/transcation-log-report.component';
import { MerchantReportComponent } from './component/merchant-report/merchant-report.component';
import { MerchantListsResolver } from './resolver/merchants-lists.resolver';

@NgModule({
  declarations: [
    CreatedVoucherReportComponent,
    PurchasedVoucherReportComponent,
    TrancationReportComponent,
    TranscationLogReportComponent,
    MerchantReportComponent
  ],
  imports: [
    ReportRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ShellModule,
  ],
  exports: [],
  providers: [
    CreatedVoucherReportResolver,
    TranscationReportResolver,
    ReportService,
    MerchantListsResolver
  ]
})
export class ReportModule { }
