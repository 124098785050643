import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../../../_services/user.service";
import { forkJoin } from "rxjs";
import { map } from 'rxjs/operators';
import { MerchantService } from "../../merchant/service/merchant.service";

@Injectable()
export class UserDetailResolver {

  constructor(private userService: UserService, private merchantService: MerchantService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let requests = [this.merchantService.getActiveMerchants(), this.userService.getRoles()];
    return forkJoin(requests).pipe(map((responses) => {
      let response = {
        merchant: responses[0],
        roles: responses[1],
      }
      return response;
    }))
  }

  // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   return forkJoin([this.merchantService.getActiveMerchants(),,this.userService.getRoles()]).pipe(map(responses => {
  //     let response = {
  //       roles : responses[0],
  //       merchant: responses[1],
  //     }
  //     return response;
  //   }));
  // }

}
