import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DashboardService } from '../../dashboard/service/dashboard.service';
declare var $: any;
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { ActivatedRoute } from '@angular/router';
import { MerchantVoucherService } from '../../merchant-voucher/service/merchant-voucher.service';
import { MerchantService } from '../../merchant/service/merchant.service';
import { ExcelService } from '../../../file-saver/excel.service';

@Component({
  moduleId: module.id,
  selector: 'dashboard',
  templateUrl: './dashboard.html',
  providers: [DashboardService]
})
export class DashboardComponent implements OnInit, AfterViewInit {

  public table: any;
  public data: any;
  public voucherPayment: any;
  public createVoucherTable: boolean = false;
  public purchasedVoucherTable: boolean = true;
  public voucherAmountTable: boolean = false;
  public registeredMerchantsTable: boolean = false;
  public createdVoucherList: any[];
  public merchantList: any

  constructor(private sciptLoaderService: ScriptLoaderService, private route: ActivatedRoute, private merchantVoucherService: MerchantVoucherService,
    private merchantServie: MerchantService, private excelService: ExcelService) {
    this.route.data.subscribe(response => {
      if (response.data.counts.payload.code == "VHR_SUCCESS_00") {
        this.data = response.data.counts.payload.responseData.counts;
      }
      if (response.data.voucherPayment.payload.code == "VHR_SUCCESS_00") {
        this.voucherPayment = response.data.voucherPayment.payload.responseData.vouchersPayment;
      }
    });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.loadTable();
    setTimeout(() => {
      this.sciptLoaderService.load('plugins');
    }, 500);
  }

  loadTable() {
    setTimeout(() => {
      this.table = $("#dashboard-list").DataTable({
        responsive: true,
        "pageLength": 20,
        fixedColumns: true,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 }
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
          }, 50);
        }
      });
    }, 50);
  }

  cratedVoucherList() {
    this.createVoucherTable = true;
    this.purchasedVoucherTable = false;
    this.voucherAmountTable = false;
    this.registeredMerchantsTable = false;

    this.merchantVoucherService.getActiveMerchantVouchers().subscribe((res) => {
      if (res.payload.code == "VHR_SUCCESS_00")
        this.createdVoucherList = res.payload.responseData.merchantVouchers;
      setTimeout(() => {
        $("#merchant-voucher-list").DataTable({
          responsive: true,
          "pageLength": 20,
          columnDefs: [
            // { responsivePriority: 1, targets: -1 },
            // { responsivePriority: 1, targets: 0 },
          ],
          drawCallback: (settings) => {
            setTimeout(() => {
              $(".tooltip").tooltip();
              $(".dropdown-trigger").dropdown({ constrainWidth: false });
            }, 50);
          }
        });
      }, 500);
    }, error => { console.log(error); })

  }

  purchasedVoucherList() {
    this.table.destroy();
    this.createVoucherTable = false;
    this.purchasedVoucherTable = true;
    this.voucherAmountTable = false;
    this.registeredMerchantsTable = false;
    this.loadTable();
  }

  voucherAmountList() {
    this.table.destroy();
    this.createVoucherTable = false;
    this.purchasedVoucherTable = false;
    this.voucherAmountTable = true;
    this.registeredMerchantsTable = false;
    this.loadTable();
  }

  registeredMerchantsList() {
    this.createVoucherTable = false;
    this.purchasedVoucherTable = false;
    this.voucherAmountTable = false;
    this.registeredMerchantsTable = true;

    this.merchantServie.getActiveMerchants().subscribe((res) => {
      if (res.payload.code == "VHR_SUCCESS_00")
        this.merchantList = res.payload.responseData.merchants;
      setTimeout(() => {
        $("#merchant-list").DataTable({
          responsive: true,
          "pageLength": 20,
          columnDefs: [
            // { responsivePriority: 1, targets: -1 },
            // { responsivePriority: 1, targets: 0 },
          ],
          drawCallback: (settings) => {
            setTimeout(() => {
              $(".tooltip").tooltip();
              $(".dropdown-trigger").dropdown({ constrainWidth: false });
            }, 50);
          }
        });
      }, 500);
    }, error => { console.log(error); })
  }

  /*
 *  Excel report download section start
 */
  createVoucherExportExcel() {
    let createdVouchers = new Array();

    for (let request of this.createdVoucherList) {
      let obj = {
        "Merchant": request.merchant,
        "Voucher Name": request.voucherName,
        "Value": request.denominationValue,
        "Currency": request.denominationCurrency,
        "Commission": request.commissionAmount,
        "Sell Start Date": request.sellDateFrom,
        "Sell End Date": request.sellDateTo,
        "Created By Buyer": request.isBuyer,
        "Created By": request.createdBy,
        "Created Date": request.createdDate,
      }
      createdVouchers.push(obj);
    }
    this.excelService.exportAsExcelFile(createdVouchers, "created_vouchers");
  }

  purchasedVoucherExportExcel() {
    let purchasedVouchers = new Array();

    for (let request of this.voucherPayment) {
      let obj = {
        "Voucher Number": request.voucherNumber,
        "Merchant": request.merchantName,
        "Voucher Date": request.createdDate,
        "Value": request.denominationValue,
        "Exc Rate": request.exchangeRate,
        "Currency": request.paymentCurrency,
        "Currency Amount": request.paymentAmount,
        "Redeemed": request.redeem,
        "Redeemed Date": request.redeemDate,
        "Merchant Order Ref.": request.merchantOrderRef,
      }
      purchasedVouchers.push(obj);
    }
    this.excelService.exportAsExcelFile(purchasedVouchers, "purchased_vouchers");
  }

  voucherAmountExportExcel() {
    let voucherAmount = new Array();

    for (let request of this.voucherPayment) {
      let obj = {
        "Voucher Number": request.voucherNumber,
        "Merchant": request.merchantName,
        "Voucher Date": request.createdDate,
        "Value": request.denominationValue,
        "Exc Rate": request.exchangeRate,
        "Currency": request.paymentCurrency,
        "Currency Amount": request.paymentAmount,
        "Redeemed": request.redeem,
        "Redeemed Date": request.redeemDate,
        "Merchant Order Ref.": request.merchantOrderRef,
      }
      voucherAmount.push(obj);
    }
    this.excelService.exportAsExcelFile(voucherAmount, "voucher_amount");
  }

  registeredMerchantsExportExcel() {
    let registeredMerchants = new Array();

    for (let request of this.voucherPayment) {
      let obj = {
        "Merchant": request.merchantName,
        "Country": request.countryName,
        "Fin Contact Person": request.financeContactPerson,
        "Fin Mobile": request.financeMobileNo,
        "Fin Email": request.financeEmail,
        "Tech Contact Person": request.technicalContactPerson,
        "Tech Mobile": request.technicalMobileNo,
        "Tech Email": request.technicalEmail,
        "Business Nature": request.businessNature,
        "Business Desc": request.businessDescription,
        "Custom Voucher Allowed": request.customVoucherAllowed,
        "Website": request.websiteUrl,
        "Redeem URL": request.redeemUrl,
        "Status": request.active,
        "Created By": request.createdByName,
        "Created Date": request.createdDate,
        "Modified By": request.modifiedByName,
        "Modified Date": request.modifiedDate,
      }
      registeredMerchants.push(obj);
    }
    this.excelService.exportAsExcelFile(registeredMerchants, "register_merchants");
  }

  /*
*  Excel report download section End
*/

}
