import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../util/http/securehttp.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class RoleService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.API_URL;
  //get permissions allowed
  getPermissions(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body = JSON.stringify({"userEmail":localStorage.getItem("userEmailAdmin")});
    // body = SystemConstants.b64EncodeUnicode(body);
    // body = JSON.stringify({ "input": body.toString()});
    return this.http.get(this.baseUrl + "permission/list", { headers })
      .pipe(map((res) => {
        // res = JSON.parse(SystemConstants.b64DecodeUnicode(res.json().output).toString());
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  getRoleTypes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + "roleType/list", { headers })
      .pipe(map((res) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  //get roles
  getRoles(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body = JSON.stringify({"userEmail":userEmail});
    // body = SystemConstants.b64EncodeUnicode(body);
    // body = JSON.stringify({"input": CryptoJS.AES.encrypt(body, this.secretKey).toString()});
    return this.http.get(this.baseUrl + "role/list", { headers })
      .pipe(map((res) => {
        // res = JSON.parse(res.json());
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }


  //get roles
  deleteRole(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body = JSON.stringify({"userEmail":userEmail});
    // body = SystemConstants.b64EncodeUnicode(body);
    // body = JSON.stringify({"input": CryptoJS.AES.encrypt(body, this.secretKey).toString()});
    return this.http.delete(this.baseUrl + "role/" + id, { headers })
      .pipe(map((res) => {
        // res = JSON.parse(res.json());
        return res;
      })).pipe(catchError((error: any) => {
        return error;
      }));
  }


  editRole(id: number, role): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body = JSON.stringify({"userEmail":userEmail});
    // body = SystemConstants.b64EncodeUnicode(body);
    // body = JSON.stringify({"input": CryptoJS.AES.encrypt(body, this.secretKey).toString()});
    return this.http.put(this.baseUrl + "role/" + id, role, { headers })
      .pipe(map((res) => {
        // res = JSON.parse(res.json());
        return res;
      })).pipe(catchError((error: any) => {
        return error;
      }));
  }

  //save Role
  saveRole(role: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body = role;
    // body = JSON.parse(body);
    // body = JSON.stringify(body);
    return this.http.post(this.baseUrl + "role/", body, { headers })
      .pipe(map((res: any) => {
        return JSON.parse(res._body);
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

}
