import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../../model/user';
import { SystemConstants } from '../../../../../constant/system/system.constant';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../../../../_services/user.service';
import { Data } from '../../../../../providers/data';
import * as _ from 'underscore';
import { Role } from '../../../model/role';
import { noWhiteSpaceValidator } from '../../../../../shell/validators/check-whitespace.directive';
import { VHRResponse } from '../../../../../util/response/response';
import { MerchantService } from '../../../../merchant/service/merchant.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'user-detail',
  templateUrl: './userDetail.html',
  providers: [UserService, MerchantService]
})

export class UserDetailComponent implements OnInit, AfterViewInit {
  public mode: string = "Add";
  public roles: Role[];
  public selectedRoles: Role[];

  public user: User;
  public message: string;
  public isSuccess: boolean = false;
  public isError: boolean = false;
  public disable: boolean = false;
  public passwordDisable: boolean = false;
  public name: String;
  public lastName: String;
  public email: String;
  public role: String;
  public userId: number;
  public merchants: any;
  public merchantList: any;
  public merchatDropdown: boolean = false;
  public showPassword: boolean = false;
  public type: string = "password"
  public showConfirmPassword: boolean = false;
  public confirmType: string = "password"

  constructor(private userService: UserService, private data: Data, private route: ActivatedRoute, private router: Router, private merchantService: MerchantService) {
    this.route.data.subscribe(routeData => {
      let response = routeData.data;
      if (response.roles.payload.code == VHRResponse.SUCCESS) {
        this.roles = response.roles.payload.responseData.roles;
      }
      if (response.merchant.payload.code == VHRResponse.SUCCESS) {
        this.merchantList = response.merchant.payload.responseData.merchants;
      }

    });
    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.form.controls.email.disable();
            this.form.controls.password.disable();
            this.form.controls.confirmPassword.disable();
          } else {
            window.location.href = "#/user/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.form.disable();
          } else {
            window.location.href = "#/user/list";
            return;
          }
        } else {
          return;
        }
      }
      if (this.mode != 'Add') {
        this.user = this.data.storage.user;
        this.selectedRoles = this.user.roleVos;
        this.selectedRoles = _.sortBy(this.selectedRoles, 'code');
      }
    });
  }

  togglePassword() {
    if (this.type === 'password') {
      this.type = 'text';
      this.showPassword = true;
    } else {
      this.type = 'password';
      this.showPassword = false;
    }
  }

  toggleConfirmPassword() {
    if (this.confirmType === 'password') {
      this.confirmType = 'text';
      this.showConfirmPassword = true;
    } else {
      this.confirmType = 'password';
      this.showConfirmPassword = false;
    }
  }

  ngOnInit() {
    if (this.user == null) {
      this.user = new User();
      this.selectedRoles = new Array();
    } else {
      let userObj = this.user;
      this.name = userObj.name;
      this.userId = userObj.userID;
      this.email = userObj.email;
      let obj = userObj.roleVos;
      let selected = userObj.status;
      this.role = _.sortBy(obj, 'code');
      this.form.get("name").setValue(this.user.name);
      this.form.get("email").setValue(this.user.email);
      this.form.get("status").setValue(this.data.storage.user.is_Active)
      this.form.get("role").setValue(this.role);
      // this.form.get("status").setValue(this.user.status);
    }
    this.getRoles();
    // this.getMerchantList();
  }

  merchantChange() {
    this.form.get('merchantValue').valueChanges.subscribe(val => {
      // console.log(val);
      if (val = true) {
        this.merchatDropdown = true;
        // Add required validator to merchantId FormControl
        this.form.get('merchantId').setValidators(Validators.required);
        this.form.get('merchantId').updateValueAndValidity(); // Trigger revalidation
        setTimeout(() => {
          $('select').formSelect();
        }, 100);
        setTimeout(() => $('select').formSelect(), 50);
      } else {
        this.merchatDropdown = false;
        // Remove required validator from merchantId FormControl
        this.form.get('merchantId').clearValidators();
        this.form.get('merchantId').updateValueAndValidity(); // Trigger revalidation
      }
      // if(val = false) {
      //   this.merchatDropdown = false;
      //   setTimeout(() => {
      //     $('select').formSelect();
      //   }, 100);
      //   setTimeout(() => $('select').formSelect(), 50);
      // }
    });
  }

  submitted = false;
  form = new FormGroup({
    name: new FormControl("", [Validators.required, noWhiteSpaceValidator]),
    status: new FormControl(true),
    email: new FormControl("", [Validators.required, Validators.email, noWhiteSpaceValidator]),
    password: new FormControl("", [Validators.required, Validators.minLength(8), noWhiteSpaceValidator]),
    confirmPassword: new FormControl("", Validators.required),
    role: new FormControl({ value: '', disabled: this.disable }),
    merchantValue: new FormControl(false),
    merchantId: new FormControl("",)
  },
    {
      // validators: this.password.bind(this)
    });


  get f() { return this.form.controls; }

  ngAfterViewInit() {
    setTimeout(() => {
      $('select').formSelect();
    }, 100);
    setTimeout(() => $('select').formSelect(), 50);
    M.updateTextFields();
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form)
    let body = this.form.getRawValue();
    console.log(body)
    console.log("body.password: ", body.password);
    console.log("body.confirmPassword: ", body.confirmPassword);
    if (body.password != body.confirmPassword) {
      this.isError = true;
      this.message = "Password and Confirm Password must be same.";
      return
    }
    this.user = this.form.value;
    this.user.userID = this.userId === undefined ? null : this.userId;
    this.user.email = this.form.value.email === undefined ? this.email : this.form.value.email;
    this.user.roleVos = this.selectedRoles;
    this.user.status = this.user.status ? 1 : 0;
    this.saveUser();
  }

  saveUser() {
    if (this.user.roleVos.length > 0) {
      this.userService.saveUser(this.user).subscribe((result) => {
        console.log(result);
        if (result != null && result.payload.code === VHRResponse.SUCCESS) {
          this.isSuccess = true;
          this.message = "User created SuccessFully";
          setTimeout(() => {
            this.router.navigate(['user', 'list']);
          }, 1500)

        } else if (result.payload.code === VHRResponse.EMAIL_ALREADY_EXIST) {
          this.isError = true;
        } else {
          this.isError = true;
          this.message = "Some thing went wrong";
        }
      }, error => { console.log(error) });
    }

  }

  getRoles() {
    if (this.roles != null && this.roles.length > 0) {
      this.roles = _.sortBy(this.roles, 'code');
      this.orderSelectedRoles();
    }
  }

  //add role to selectedRoles
  addRole(role: any) {
    if (!role.status)
      return;
    this.selectedRoles.push(role);
    this.selectedRoles = _.sortBy(this.selectedRoles, 'code');
    this.roles.splice(this.roles.indexOf(role), 1);
  }
  // remove role from selectedRoles
  removeRole(role: any) {
    this.roles.push(role);
    this.selectedRoles.splice(this.selectedRoles.indexOf(role), 1);
    this.roles = _.sortBy(this.roles, 'code');
  }
  //odering selected roles
  orderSelectedRoles() {
    if (this.selectedRoles != null && this.selectedRoles.length > 0) {
      for (let selectedRole of this.selectedRoles) {
        for (let role of this.roles) {
          if (role.id === selectedRole.id) {
            this.roles.splice(this.roles.indexOf(role), 1);
          }
        }
      }
    }
    setTimeout(() => {
      $('.chips').chips({
        disbaled: true
      });
    }, 50);
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  getMerchantList() {
    this.merchantService.getActiveMerchants().subscribe((res) => {
      if (res != null && res.responseData != null) {
        this.merchants = res.responseData.merchants;
        // console.log(this.merchants);
      }
    }, error => { console.log(error); })
  }

}
