import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { ContactUsService } from './service/contact-us.service';
import { ContactUsListResolver } from './resolver/contact-us-list.resolver';
import { ContactUsComponent } from './component/detail/contact-us.component';
import { ContactUsListComponent } from './component/list/contact-us-list.component';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    ContactUsService,
    ContactUsListResolver
  ],
  declarations: [
    ContactUsComponent,
    ContactUsListComponent
  ],
  exports: [
    ContactUsComponent,
    ContactUsListComponent
  ]
})
export class ContactUsModule { }
