import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginComponent } from './component/login.component';
import { LoginService } from './service/login.service';
import { DecodeInterceptor } from '../../constant/decodeInterceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { AuthInterceptor } from '../../constant/authInterceptor.service';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	imports: [
		BrowserModule,
		CommonModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	providers: [LoginService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: DecodeInterceptor, multi: true },
	],
	declarations: [
		LoginComponent
	],
	exports: [
		LoginComponent
	]
})
export class LoginModule { }
