import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DecodeInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('DecodeInterceptor: intercepting request', req);
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          // console.log('DecodeInterceptor: received response', event);
          if (event.body && event.body.payload) {
            const decodedBody = this.decodeBase64(event.body.payload);
            // console.log('Decoded Body:', decodedBody);
            return event.clone({ body: { ...event.body, payload: decodedBody } });
          } else {
            console.warn('DecodeInterceptor: No payload found in response body');
          }
        }
        return event;
      })
    );
  }

  private decodeBase64(input: string): any {
    if (typeof input === 'string') {
      const str = atob(input);
      try {
        return JSON.parse(str);
      } catch (error) {
        console.error('DecodeInterceptor: Error parsing JSON', error);
        return str;
      }
    }
    return input;
  }
}
