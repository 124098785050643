import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../../../_services/user.service";

@Injectable()
export class UserListResolver implements Resolve<any> {
    constructor(public userService : UserService){}
    
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.getUsers();
    }
}