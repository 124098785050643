import { NgModule } from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { CountryComponent } from '../country/component/detail/country.component';
import { CountryListComponent } from './component/list/country-list.component';
import { CountryService } from './service/country.service';
import { NgxMaskModule } from 'ngx-mask';
import { CountryListResolver } from '../country/resolver/country-list.resolver';





@NgModule ({
  imports: [
       CommonModule,
       FormsModule,
       ReactiveFormsModule,
       NgxMaskModule.forRoot()
   	],
  providers:[
    CountryService,
    CountryListResolver
  ],

  declarations: [
    CountryComponent,
    CountryListComponent,
  ],
  exports: [
    CountryComponent,
    CountryListComponent,

  ]
})
export class CountryModule {}
