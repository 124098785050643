import { Injectable } from "@angular/core";
import { ContactUsService } from "../service/contact-us.service";




@Injectable()
export class ContactUsListResolver {

    constructor(public service : ContactUsService ){}

    resolve(){
        return this.service.getRequestList();
    }

}