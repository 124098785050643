import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response';

declare var M: any;
declare var $: any;

@Component({
  selector: 'contact-us-detail',
  templateUrl: './contactUs.html',
  providers: []
})
export class ContactUsComponent implements OnInit {
  public mode: any;
  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;
  submitted = false;
  public request: any

  constructor(private formBuilder: FormBuilder, private router: Router, private data: Data, private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef) {

    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.request = this.data.storage.request;
            console.log(this.request);

          } else {
            window.location.href = "#/merchant-request/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.requestForm.disable();
          } else {
            window.location.href = "#/merchant-request/list";
            return;
          }
        } else {
          return;
        }
        this.request = this.data.storage.request;
      }
    });
  }

  requestForm = this.formBuilder.group({
    merchantOnboardingRequestId: [''],
    companyName: [''],
    emailAddress: [''],
    name: [''],
    contactNo: [''],
    message: [''],
    status: [''],
  });


  get f() { return this.requestForm.controls; }

  ngOnInit() { }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('select').formSelect();
    }, 100);
    if (this.mode != 'Add') {
      this.requestForm.patchValue(this.request);
      M.updateTextFields();
      setTimeout(() => $('select').formSelect(), 50);
    }
  }

  onSubmit() {

  }

}
