import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { VoucherDenominationService } from '../voucher-denomination/service/voucher-denomination.service';
import { VoucherDenominationListComponent } from '../voucher-denomination/component/list/voucher-denomination-list.component';
import { VoucherDenominationComponent } from '../voucher-denomination/component/detail/voucher-denomination.component';
import { VoucherDenominationResolver } from '../voucher-denomination/resolver/voucher-denomination.resolver';
import { VoucherDenominationListResolver } from '../voucher-denomination/resolver/voucher-denomication-list.resolver';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    VoucherDenominationService,
    VoucherDenominationResolver,
    VoucherDenominationListResolver
  ],
  declarations: [
    VoucherDenominationListComponent,
    VoucherDenominationComponent
  ],
  exports: [
    VoucherDenominationListComponent,
    VoucherDenominationComponent
  ]
})
export class VoucherDenominationModule { }
