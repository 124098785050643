export class Country {

    public id: number;
    public name: string;
    public code: number;
    public alphaISO2Code: string;
    public alphaISO3Code: string;
    public isoDigitCode: string;
    public createdDate: string;
    public updatedDate: string;
    public status: boolean = true;
}