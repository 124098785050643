import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PaypointFetchDataService } from './service/paypoint-fetch-data.service';
import { FetchCountryComponent } from './fetch-country/fetch-country.component';
import { FetchCompaniesComponent } from './fetch-companies/fetch-companies.component';
import { FetchVouchersComponent } from './fetch-vouchers/fetch-vouchers.component';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    PaypointFetchDataService
  ],
  declarations: [
    FetchCountryComponent,
    FetchCompaniesComponent,
    FetchVouchersComponent
  ],
  exports: [
    FetchCountryComponent,
    FetchCompaniesComponent,
    FetchVouchersComponent
  ]
})
export class PaypointFetchDataModule { }
