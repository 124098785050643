import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../../../util/http/securehttp.service';
// import { SystemConstants } from '../../system/system.constants';
import { Observable } from 'rxjs';
import { ShellComponent } from '../../../shell/shell.component';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MerchantService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.API_URL;
  // private headers = SystemConstants.header;
  // private secretKey: string = environment.SECRET_KEY;

  /*
* get all active Merchants
*/

  getActiveMerchants(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + "merchant/all", { headers })
      .pipe(map((res: any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }
  getActiveMerchantLists(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + "merchant/fetchList", { headers })
      .pipe(map((res: any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  /*
* Save Merchant
*/
  saveMerchant(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const objBase64 = btoa(JSON.stringify(body));
    let payload = {
      "payload": objBase64
    }
    return this.http.post(this.baseUrl + `merchant/add`, payload, { headers })
      .pipe(map((res: any) => {
        return res.payload;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }


}