import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { CountryService } from "../../country/service/country.service";
import {CurrencyService} from '../../currency/service/currency.service';
import { MerchantService } from "../../merchant/service/merchant.service";



@Injectable()
export class MerchantBannerResolver {

    constructor( private merchantService: MerchantService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requests = [this.merchantService.getActiveMerchants()];
        return forkJoin(requests).pipe(map((responses) => {
            let response = {
                merchant: responses[0]
            }
            return response;
        }))
    }

}