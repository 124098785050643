import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyComponent } from './component/detail/currency.component';
import { CurrencyListComponent } from './component/list/currency-list.component';
import { CurrencyService } from './service/currency.service';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyListResolver } from '../currency/resolver/currency-list.resolver';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    CurrencyService,
    CurrencyListResolver
  ],
  declarations: [
    CurrencyComponent,
    CurrencyListComponent,
  ],
  exports: [
    CurrencyComponent,
    CurrencyListComponent,
  ]
})
export class CurrencyModule { }
