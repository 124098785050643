import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response';
import { VoucherDenominationService } from '../../service/voucher-denomination.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'voucher-denomination-detail',
  templateUrl: './voucherDenomination.html',
  providers: [VoucherDenominationService]
})
export class VoucherDenominationComponent implements OnInit {
  public mode: any;
  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;
  submitted = false;
  currencyList: any;
  merchantList: any;
  public voucher: any

  constructor(private formBuilder: FormBuilder, private router: Router, private data: Data, private route: ActivatedRoute,
    private voucherService: VoucherDenominationService, private changeDetector: ChangeDetectorRef) {

    this.route.data.subscribe(response => {
      console.log(response);
      this.merchantList = response.data.merchant.responseData.merchants;
      this.currencyList = response.data.currency.responseData.currencies;
    });
    
    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.voucher = this.data.storage.voucher;
            console.log(this.voucher);
            
          } else {
            window.location.href = "#/voucher-denomination/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.voucherForm.disable();
          } else {
            window.location.href = "#/voucher-denomination/list";
            return;
          }
        } else {
          return;
        }
        this.voucher = this.data.storage.voucher;
      }
    });
  }

  voucherForm = this.formBuilder.group({
    voucherDenominationID: [''],
    denominationValue: ['', Validators.required],
    custom: [false],
    currencyId: ['', Validators.required],
    commPercentage: [false],
    merchantId: ['', Validators.required],
    commission: ['', Validators.required],
    active: [true],
  });


  get f() { return this.voucherForm.controls; }

  ngOnInit() { }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('select').formSelect();
    }, 100);
    if (this.mode != 'Add') {
      this.voucherForm.patchValue(this.voucher);
      M.updateTextFields();
      setTimeout(() => $('select').formSelect(), 50);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.voucherForm.invalid) {
      return;
    }
    console.log(this.voucherForm.getRawValue());
    let body = this.voucherForm.getRawValue()
    this.voucherService.saveVoucher(body).subscribe(response => {
      if (response.code == VHRResponse.SUCCESS) {
        this.isSuccess = true;
        this.message = this.mode == 'Add' ? "SUCCESS!  Created SuccessFully" : "SUCCESS!  Updated SuccessFully";
        setTimeout(() => {
          this.router.navigate(['voucher-denomination', 'list']);
        }, 1500)
      }
      else if (response.code == VHRResponse.UNKNOWN_ERROR) {
        this.isError = true;
      } else {
        this.isError = true;
      }
    });
  }

}
