import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError,map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpService } from '../util/http/securehttp.service';
import { Headers } from '@angular/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ScriptLoaderService } from './script-loader.service';
import { SystemConstants } from '../constant/system/system.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public defaultWarehouseSelected: EventEmitter<any> = new EventEmitter<any>();
  private baseUrl = environment.API_URL;

  constructor(private router: Router, private http: HttpService, private ngxService: NgxUiLoaderService, private scriptLoaderService: ScriptLoaderService) { }

  hasPermission(key: string){
    let permissions = this.getLocalStorageItem('evs_permissions');
    return permissions[key];
  }

  initializeAuthorization(user: any){
    let permissions = {};
    let isAdmin = false;
    for (const role of user.roleVos) {
      if(role.code == 'SPAdmin' || role.code == 'SYAdmin') {
        isAdmin = true;
      }
      for (const permission of role.permissionVos) {
        permissions[permission.code] = true;
      }
    }
    let _user = {
      id: user.userID,
      email: user.email,
      firstName: user.firstName == null? '': user.firstName,
      lastName: user.lastName == null? '': user.lastName,
      type: user.type,
      isAdmin : isAdmin
    };
    this.setLocalStorageItem('evs_permissions',permissions);
    this.setLocalStorageItem('evs_user',_user);
  }

  isUserLoggedIn(){
    return this.getLocalStorageItem('evs_user') == null? false: true;
  }

  logout(){
    this.scriptLoaderService.clearAll();
    localStorage.clear();
    this.router.navigate(['login']);
    setTimeout(() => {
      this.ngxService.stopAll();
    }, 100);
  }

  getLocalStorageItem(key: string){
    let data = localStorage.getItem(SystemConstants.b64EncodeUnicode(key));
    if(data == null)
      return null;
    return JSON.parse(SystemConstants.b64DecodeUnicode(data));
  }

  setLocalStorageItem(key: string, value: any){
    localStorage.setItem(SystemConstants.b64EncodeUnicode(key), SystemConstants.b64EncodeUnicode(JSON.stringify(value)));
  }

  getLoggedInUser(){
    return this.getLocalStorageItem('evs_user');
  }

  getToken(){
    return localStorage.getItem(SystemConstants.b64EncodeUnicode('auth_token'));
  }

  setToken(token){
    localStorage.setItem(SystemConstants.b64EncodeUnicode('auth_token'), token);
  }

  getRefreshToken(){
    return localStorage.getItem(SystemConstants.b64EncodeUnicode('refresh_token'));
  }

  setRefreshToken(refreshToken){
    localStorage.setItem(SystemConstants.b64EncodeUnicode('refresh_token'), refreshToken);
  }
}
