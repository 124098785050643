import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response';
import { MerchantService } from '../../service/merchant.service';
import { DomSanitizer, Title } from '@angular/platform-browser';

declare var M: any;
declare var $: any;

@Component({
  selector: 'merchant-detail',
  templateUrl: './merchant.html',
  providers: [MerchantService]
})

export class MerchantComponent implements OnInit {
  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;
  submitted = false;
  public countryList: any;
  public categoryList: any;
  public mode: any;
  public merchant: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: Data, private route: ActivatedRoute, private sanitizer: DomSanitizer,
    private merchantService: MerchantService, private changeDetector: ChangeDetectorRef) {
    this.route.data.subscribe(response => {
      this.countryList = response.data.country.payload.responseData.countries;
      if (response.data.category.payload.code === VHRResponse.SUCCESS) {
        this.categoryList = response.data.category.payload.responseData.Data;
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          // console.log(this.merchant);
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.merchant = this.data.storage.merchant;
            // console.log(this.merchant);
          } else {
            window.location.href = "#/merchant/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.merchantForm.disable();
          } else {
            window.location.href = "#/merchant/list";
            return;
          }
        } else {
          return;
        }
        this.merchant = this.data.storage.merchant;
      }
    });
  }

  merchantForm = this.formBuilder.group({
    merchantID: [''],
    merchantName: ['', Validators.required],
    countryId: ['', Validators.required],
    catId: ['', Validators.required],
    financeContactPerson: ['', Validators.required],
    financeMobileNo: ['', Validators.required],
    financeEmail: ['', Validators.required],
    technicalContactPerson: ['', Validators.required],
    technicalMobileNo: ['', Validators.required],
    technicalEmail: ['', Validators.required],
    businessNature: ['', Validators.required],
    businessDescription: [''],
    websiteUrl: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]],
    redeemUrl: ['', Validators.required],
    active: [true],
    customVoucherAllowed: [true],
    bannerImageUrl: [''],
    logoImageUrl: [''],
    bannerImageUrlStr: [''],
    bannerImageFileName: [''],
    logoImageUrlStr: [''],
    logoImageFileName: [''],
    merchantCode: ['', Validators.required],
  });

  get f() { return this.merchantForm.controls; }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('select').formSelect();
    }, 100);
    if (this.mode != 'Add') {
      this.merchantForm.patchValue(this.merchant);
      M.updateTextFields();
      setTimeout(() => $('select').formSelect(),
        M.updateTextFields(), 50);
    }
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.merchantForm.getRawValue());
    if (this.merchantForm.invalid) {
      return;
    }
    let body = this.merchantForm.getRawValue()
    console.log(body);
    this.merchantService.saveMerchant(body).subscribe(response => {
      console.log(response);
      if (response.code == VHRResponse.SUCCESS) {
        this.isSuccess = true;
        this.message = this.mode == 'Add' ? "SUCCESS!  Created SuccessFully" : "SUCCESS!  Updated SuccessFully";
        setTimeout(() => {
          this.router.navigate(['merchant', 'list']);
        }, 1500)
      }
      else if (response.code == VHRResponse.UNKNOWN_ERROR) {
        this.isError = true;
      } else {
        this.isError = true;
      }
    });
  }

  addBannerImage(filename, bannerImageBase64) {
    this.merchantForm.controls.bannerImageUrl = new FormGroup({
      bannerImageBase64: new FormControl(bannerImageBase64),
      filename: new FormControl(filename),
    });
  }

  handleBannerImageUpload(files) {
    const reader = new FileReader();
    let filename = files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      let bannerGrp = new FormGroup({
        bannerImageBase64: new FormControl(reader.result),
        filename: new FormControl(filename),
      });
      this.merchantForm.get('bannerImageUrlStr').setValue(bannerGrp.value.bannerImageBase64);
      this.merchantForm.get('bannerImageFileName').setValue(bannerGrp.value.filename);
      // this.merchantForm.setControl('bannerImageUrl', bannerGrp);
    }
  }

  getSanitizedImageBase64() {
    //   let bannerImageUrl = this.merchantForm.controls.bannerImageUrl;
    let bannerImageBase64 = this.merchantForm.get('bannerImageUrlStr').value;
    bannerImageBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(bannerImageBase64);
    return bannerImageBase64;
  }

  openImageInNewTab() {
    let url = this.getSanitizedImageBase64();
    url = url.changingThisBreaksApplicationSecurity;
    let img = '<img src="' + url + '">';
    let w = window.open();
    w.document.write(img);
  }

  addLogoImage(logoFilename, logoImageBase64) {
    this.merchantForm.controls.logoImageUrl = new FormGroup({
      logoImageBase64: new FormControl(logoImageBase64),
      logoFilename: new FormControl(logoFilename),
    });
  }

  handleLogoImageUpload(files) {
    const reader = new FileReader();
    let logoFilename = files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      let logoGrp = new FormGroup({
        logoImageBase64: new FormControl(reader.result),
        logoFilename: new FormControl(logoFilename),
      });
      this.merchantForm.get('logoImageUrlStr').setValue(logoGrp.value.logoImageBase64);
      this.merchantForm.get('logoImageFileName').setValue(logoGrp.value.logoFilename);
      // this.merchantForm.setControl('bannerImageUrl', bannerGrp);
    }
  }

  getSanitizedLogoImageBase64() {
    //   let bannerImageUrl = this.merchantForm.controls.bannerImageUrl;
    let logoImageBase64 = this.merchantForm.get('bannerImageUrlStr').value;
    logoImageBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(logoImageBase64);
    return logoImageBase64;
  }

  openLogoImageInNewTab() {

    let url = this.getSanitizedLogoImageBase64();
    url = url.changingThisBreaksApplicationSecurity;
    let img = '<img src="' + url + '">';
    let w = window.open();
    w.document.write(img);
  }

  removeImage() {
    this.merchantForm.removeControl('bannerImageUrl');
  }
  // removeImage(index) {
  //   let bannerImageUrl = <FormArray>this.merchantForm.controls.bannerImageUrl;
  //   bannerImageUrl.removeAt(index);
  // }

}
