import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../../file-saver/excel.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthorizationService } from '../../../../_services/authorization.service';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';
import { ReportService } from '../../service/report.service';
declare var $: any;

@Component({
  selector: 'created-voucher-report',
  templateUrl: './createdVoucherReport.html'
})
export class CreatedVoucherReportComponent implements OnInit, AfterViewInit {

  createdVoucher: any[];
  searchForm: FormGroup;

  table: any;
  backupList: any;
  user: any;
  public merchantList: any;

  constructor(private route: ActivatedRoute, private excelService: ExcelService, private authService: AuthorizationService,
    public ngxloader: NgxUiLoaderService, private reportService: ReportService) {

    this.route.data.subscribe(response => {
      if (response.data.payload.code === VHRResponse.SUCCESS) {
        this.merchantList = response.data.payload.responseData.merchants;
      }
    });

    this.searchForm = new FormGroup({
      merchantId: new FormControl(''),

    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    setTimeout(() => {
      $('select').formSelect();
      $('.datepicker').datepicker({ format: 'mm/dd/yyyy', });
    },
      50);
    this.initializeTable();
  }


  exportExcel() {
    let createdVoucher = new Array();
    for (let voucher of this.createdVoucher) {
      let voucherObj = {
        "Voucher Name": voucher.voucherName,
        "Display Name": voucher.voucherDisplayName,
        "Voucher Commission": voucher.commisionAmount,
        "Price": voucher.denominationAmount,
        "Country": voucher.countryName,
        "Status": voucher.isActive,
        "SD From": voucher.sellDateFrom,
        "SD To": voucher.sellDateTo,
        "createdDate": voucher.createdDate
      }
      createdVoucher.push(voucherObj);
    }
    this.excelService.exportAsExcelFile(createdVoucher, "Created_Voucher_Report");
  }

  search() {
    let searchFormValues = this.searchForm.getRawValue();
    this.ngxloader.start();
    this.reportService.createdVoucherReport(searchFormValues).subscribe(res => {
      if (res.payload.code === "VHR_SUCCESS_00") {
        this.createdVoucher = res.payload.responseData.data;
        this.backupList = this.createdVoucher;
        this.table.destroy();
        this.initializeTable();
        this.ngxloader.stop();
      }
    }, error => {
      console.error("Report Fetch Failed : " + error)
    });
    this.ngxloader.stop();
  }

  initializeTable() {
    setTimeout(() => {
      this.table = $("#voucher-list").DataTable({
        responsive: true,
        "pageLength": 100,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 },
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
            $(".dropdown-trigger").dropdown({ constrainWidth: false });
          }, 50);
        }
      });
    }, 50);
  }

  filter() { }

}
