import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../../file-saver/excel.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthorizationService } from '../../../../_services/authorization.service';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $ : any;

@Component({
  selector: 'purchased-voucher-report',
  templateUrl: './purchasedVoucherReport.html'
})
export class PurchasedVoucherReportComponent implements OnInit, AfterViewInit {

  purchasedVoucher : any[];
  searchForm : FormGroup;

  table:any;
  backupList: any;
  user : any;

  constructor(private route: ActivatedRoute, private excelService: ExcelService, private authService: AuthorizationService, public ngxloader: NgxUiLoaderService) {



    this.searchForm = new FormGroup({
      client : new FormControl(''),
      item: new FormControl(''),
      warehouse: new FormControl('')
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    setTimeout(()=>{
      $('select').formSelect();
      $('.datepicker').datepicker({ format: 'mm/dd/yyyy', });
    },
    50);
    // this.initializeTable();
  }


  exportExcel() {
  }


  search() {
   
  }

  // initializeTable() {
  //   setTimeout(() => {
  //     this.table = $("#stock-list").DataTable({
  //       responsive: true,
  //       "pageLength" : 100,
  //       columnDefs: [
  //         { responsivePriority: 1, targets: -1 },
  //         { responsivePriority: 1, targets: 0 },
  //       ],
  //       drawCallback: (settings) => {
  //         setTimeout(() => {
  //           $(".tooltip").tooltip();
  //           $(".dropdown-trigger").dropdown({constrainWidth: false});
  //         }, 50);
  //       }
  //     });
  //   }, 50);
  // }

  filter() {}

}
