import { Injectable } from "@angular/core";
import { CurrencyService } from "../service/currency.service";


@Injectable()
export class CurrencyListResolver {

    constructor(public currencyService : CurrencyService ){}

    resolve(){
        return this.currencyService.getActiveCurrencies();
    }

}