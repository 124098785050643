import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../util/http/securehttp.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  private baseUrl = environment.API_URL;
  //get roles
  getRoles() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body = JSON.stringify({"userEmail":localStorage.getItem("userEmailAdmin")});
    // body = SystemConstants.b64EncodeUnicode(body);
    // body = JSON.stringify({ "input": body.toString()});
    return this.http.get(this.baseUrl + "role/list", { headers })
      .pipe(map((res:any) => {
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  //getUsers
  getUsers(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body = {"userEmail":sessionStorage.getItem("userEmailAdmin")};
    return this.http.get(this.baseUrl + "user/list", { headers })
      .pipe(map((res: any) => {
        // return JSON.parse(res._body);
        return res;
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  //save Users
  saveUser(user: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body: any = JSON.stringify({ "userVo": user });
    return this.http.post(this.baseUrl + "user/register", body, { headers })
      .pipe(map((res: any) => {
        return JSON.parse(res._body);
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }))
  }

  //reset-password
  resetPassword(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body: any = null; //SystemConstants.b64EncodeUnicode(JSON.stringify({
    //   "userEmail":sessionStorage.getItem("userEmailAdmin"),
    //   "email":email,
    //   "password":password
    // }));
    //body = JSON.stringify({"input": body.toString()});
    body = {
      "userEmail": sessionStorage.getItem("userEmailAdmin"),
      "email": email,
      "password": password
    };
    return this.http.post(this.baseUrl + "user/service/resetPassword", body, { headers })
      .pipe(map((res: any) => {
        // res = JSON.parse(SystemConstants.b64DecodeUnicode(res.output).toString());
        return JSON.parse(res._body);
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  deleteUser(childEmail: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let body: any = SystemConstants.b64EncodeUnicode(JSON.stringify({
    //                   "userEmail":sessionStorage.getItem("userEmailAdmin"),
    //                   "childEmail":childEmail
    //                 }));
    // body = JSON.stringify({"input": body.toString()});
    let body: any = {
      "userEmail": sessionStorage.getItem("userEmailAdmin"),
      "childEmail": childEmail
    };
    return this.http.post(this.baseUrl + "user/service/delete", body, { headers })
      .pipe(map((res: any) => {
        // res = JSON.parse(SystemConstants.b64DecodeUnicode(res.output).toString());
        return JSON.parse(res._body);
      })).pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }));
  }

  changePassword(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + "user/password/reset", body, { headers })
      .pipe(map((response:any) => {
        return response;
      }))
      .pipe(catchError((error: any) => {
        console.log(error);
        return error;
      }))
  }

}
