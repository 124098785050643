import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../../file-saver/excel.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthorizationService } from '../../../../_services/authorization.service';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';
import { ReportService } from '../../service/report.service';
declare var $: any;

@Component({
  selector: 'merchant-report',
  templateUrl: './merchantReport.html'
})
export class MerchantReportComponent implements OnInit, AfterViewInit {

  merchants: any[];
  searchForm: FormGroup;

  table: any;
  backupList: any;
  user: any;
  public merchantList: any;

  constructor(private route: ActivatedRoute, private excelService: ExcelService, private authService: AuthorizationService,
    public ngxloader: NgxUiLoaderService, private reportService: ReportService) {

    this.searchForm = new FormGroup({
      merchantName: new FormControl(''),
      createdDateFrom: new FormControl(''),
      createdDateTo: new FormControl(''),
    })
  }

  ngOnInit() {
    // this.search();
  }

  ngAfterViewInit() {

    setTimeout(() => {
      $('select').formSelect();
      $('.datepicker').datepicker({ format: 'mm/dd/yyyy', });
    },
      50);
    this.initializeTable();
  }


  exportExcel() {
    let transcations = new Array();
    for (let merchant of this.merchants) {
      let voucherObj = {
        "Merchant Name": merchant.merchantCode,
        "Business Description": merchant.businessDescription,
        "Country": merchant.countryName,
        "User": merchant.userName,
        "Category": merchant.categoryName,
        // "Fin Contact Person": merchant.financeContactPerson,
        // "Fin Mobile": merchant.financeContactNo,
        // "Fin Email": merchant.financeEmail,
        // "Tech Contact Person": merchant.technicalContactPerson,
        // "Tech Mobile": merchant.technicalPersonContactNo,
        // "Tech Email": merchant.technicalPersonEmail,
        // "Business Nature": merchant.businessNature,
        // "Business Desc": merchant.businessDescription,
        // "Custom Voucher Allowed": merchant.isCustomVoucherAllow,
        // "Banner Image": merchant.bannerImage,
        // "Logo Image": merchant.logoImage,
        // "Website": merchant.websiteUrl,
        // "Redeem URL": merchant.redeemUrl,
        // "Status": merchant.isActive,
        // "Created Date": merchant.createdDate
      }
      transcations.push(voucherObj);
    }
    this.excelService.exportAsExcelFile(transcations, "Merchant_Report");
  }

  search() {
    this.searchForm.controls.createdDateFrom.setValue($("#createdDateFrom").val());
    this.searchForm.controls.createdDateTo.setValue($("#createdDateTo").val());
    let searchFormValues = this.searchForm.getRawValue();
    this.ngxloader.start();
    this.reportService.merchantReport(searchFormValues).subscribe(res => {
      console.log(res);
      if (res.payload.code === "VHR_SUCCESS_00") {
        this.merchants = res.payload.responseData.data;
        this.backupList = this.merchants;
        this.table.destroy();
        this.initializeTable();
        this.ngxloader.stop();
      }
    }, error => {
      console.error("Report Fetch Failed : " + error)
    });
    this.ngxloader.stop();
  }

  initializeTable() {
    setTimeout(() => {
      this.table = $("#voucher-list").DataTable({
        responsive: true,
        "pageLength": 100,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 },
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
            $(".dropdown-trigger").dropdown({ constrainWidth: false });
          }, 50);
        }
      });
    }, 50);
  }

  filter() { }

}
