import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../service/login.service';
import { User } from '../../../module/user/model/user';
import { AuthorizationService } from '../../../_services/authorization.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from '../../../_services/cookie.service';
import { VHRResponse } from '../../..//util/response/response';
import { CryptoService } from './../../auth-token/crypto.service';
declare var $: any;
declare var M: any;

@Component({
  moduleId: module.id,
  selector: 'user-login',
  templateUrl: './userLogin.html',
  providers: [LoginService, AuthorizationService]
})

export class LoginComponent implements OnInit, AfterViewInit {

  encryptedText: any;
  public userEmail: string;
  public password: string;
  public newPassword: string;
  public isLogin: boolean;
  public isError: boolean = false;
  public errorMessage = "";
  public user: User;
  public permissions: any[];
  public rememberMe: any;
  public merchant: boolean = true
  public showPassword: boolean = false;
  constructor(private ngxService: NgxUiLoaderService, private loginService: LoginService, private cryptoService: CryptoService,
    private authorizationService: AuthorizationService, private router: Router, private cookieService: CookieService) {

    if (this.authorizationService.isUserLoggedIn()) {
      if (window.location.hash === '#/login') {
        window.location.href = "#/dashboard";
      } else {
        window.location.href = window.location.hash;
      }
    }

    let uid = this.cookieService.readCookie("UID");
    if (uid != null || uid != '') {
      this.userEmail = uid;
      this.password = this.cookieService.readCookie("CRD");
      setTimeout(() => {
        $(".overlayLogin").hide();
        M.updateTextFields();
      }, 50)

    }


  }

  ngOnInit() { }
  ngAfterViewInit() { }

  onSubmit() {
    this.validateForm();
    if (!this.isError) {
      this.login();

      // const element = document.getElementById('rememberMe') as HTMLInputElement

      // if (element.checked == true) {
      //   this.cookieService.createCookie("UID", this.userEmail, 365);
      //   this.cookieService.createCookie("CRD", this.password, 365);
      // }

    }
  }

  async encryptText() {
    const plaintext: any = this.password;
    const key = 'pa$$w0rd!#%&(@$^*)pa$$w0rd!#%&(@';
    this.encryptedText = await this.cryptoService.encrypt(plaintext, key);
  }

  async login() {
    try {
      await this.encryptText(); // Wait for encryption to complete
      let body = {
        loginId: this.userEmail,
        password: this.encryptedText,
        merchant: this.merchant
      }
      this.ngxService.start();
      this.loginService.userLogin(body).subscribe((result) => {
        this.ngxService.stop();
        if (result.payload.code === VHRResponse.SUCCESS && result.payload.responseData != null) {
          this.isLogin = true;
          this.user = result.payload.responseData.user;
          this.authorizationService.initializeAuthorization(this.user);
          this.router.navigate(['dashboard']);
        } else {
          this.isError = true;
          this.errorMessage = "Invalid username or password provided";
          $(".overlayLogin").hide();
        }
      }, error => {
        console.log("Error Occured : " + error)
      });
    } catch (error) {
      console.error('Encryption error:', error);
      // Handle encryption error
    }
  }

  logout() {
    this.authorizationService.logout();
  }

  validateForm() {
    this.isError = false;
    this.errorMessage = "";
    if (this.userEmail !== undefined && this.userEmail !== null && this.userEmail !== "") {
      if (this.userEmail.trim().length !== 0 && this.userEmail.trim().length === this.userEmail.length) {

      } else {
        this.isError = true;
        this.errorMessage = "Please enter valid username";
        return;
      }
    } else {
      this.isError = true;
      this.errorMessage = "Please enter valid username";
      return;
    }
    if (this.password == "" || this.password.trim().length == 0) {
      this.isError = true;
      this.errorMessage = "Please enter valid username";
      return;
    }
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
