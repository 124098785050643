import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../../file-saver/excel.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthorizationService } from '../../../../_services/authorization.service';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';
import { ReportService } from '../../service/report.service';
declare var $: any;

@Component({
  selector: 'transcation-report',
  templateUrl: './transcationReport.html'
})
export class TrancationReportComponent implements OnInit, AfterViewInit {

  transcations: any[];
  searchForm: FormGroup;

  table: any;
  backupList: any;
  user: any;
  public merchantList: any;

  constructor(private route: ActivatedRoute, private excelService: ExcelService, private authService: AuthorizationService,
    public ngxloader: NgxUiLoaderService, private reportService: ReportService) {

    this.route.data.subscribe(response => {
      if (response.data.payload.code === VHRResponse.SUCCESS) {
        this.merchantList = response.data.payload.responseData.merchants;
      }
    });

    this.searchForm = new FormGroup({
      merchantId: new FormControl(''),
      createdDateFrom: new FormControl(''),
      createdDateTo: new FormControl(''),
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    setTimeout(() => {
      $('select').formSelect();
      $('.datepicker').datepicker({ format: 'mm/dd/yyyy', });
    },
      50);
    this.initializeTable();
  }


  exportExcel() {
    let transcations = new Array();
    for (let voucher of this.transcations) {
      let voucherObj = {
        "Txn Ref No": voucher.txnRefNo,
        "Merchant Name": voucher.merchantName,
        "Voucher Name": voucher.voucherName,
        "Voucher ID": voucher.merchantVoucherId,
        // "First Name": voucher.firstName,
        // "Last Name": voucher.lastName,
        "Email Address": voucher.emailAddress,
        // "Beneficiary Account No:": voucher.beneficiaryAccountNo,
        // "Denomination Value": voucher.denominationValue,
        // "Is Active": voucher.isAvtive,
        "Paid Price": voucher.paymentAmount,
        "Redeem Validity": voucher.redeemValidty,
        "Redeem Status": voucher.isRedeem,
        "Paypoint Operation ID": voucher.paypointOperationId,
        "Country": voucher.countryName,
        "Paypoint Description": voucher.ppResponseForPay,
        // "Redeem Date": voucher.redeemDate,
        // "Voucher No:": voucher.voucherNumber,
        // "Created Date": voucher.createdDate,
        "Payment Status": voucher.paymentStatus,
      }
      transcations.push(voucherObj);
    }
    this.excelService.exportAsExcelFile(transcations, "Transcation_Report");
  }

  search() {
    this.searchForm.controls.createdDateFrom.setValue($("#createdDateFrom").val());
    this.searchForm.controls.createdDateTo.setValue($("#createdDateTo").val());
    let searchFormValues = this.searchForm.getRawValue();
    this.ngxloader.start();
    this.reportService.transcationReport(searchFormValues).subscribe(res => {
      if (res.payload.code === "VHR_SUCCESS_00") {
        this.transcations = res.payload.responseData.data;
        this.backupList = this.transcations;
        this.table.destroy();
        this.initializeTable();
        this.ngxloader.stop();
      }
    }, error => {
      console.error("Report Fetch Failed : " + error)
    });
    this.ngxloader.stop();
  }

  initializeTable() {
    setTimeout(() => {
      this.table = $("#voucher-list").DataTable({
        responsive: true,
        "pageLength": 100,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 },
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
            $(".dropdown-trigger").dropdown({ constrainWidth: false });
          }, 50);
        }
      });
    }, 50);
  }

  filter() { }

}
