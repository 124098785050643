import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryService } from '../../service/country.service';
import { Country } from '../../model/country';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response'

declare var $: any;

@Component({
  selector: 'country-detail',
  templateUrl: './country.html',
  providers: [CountryService]
})
export class CountryComponent implements OnInit {
  public message: any;
  public isSuccess: boolean = false;
  public isError: boolean = false;
  public mode: string = "Add";
  submitted = false;
  country: Country = new Country();

  constructor(private formBuilder: FormBuilder, private router: Router, private countryService: CountryService,
    private route: ActivatedRoute, private data: Data) {
    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.country = this.data.storage.country;
          } else {
            window.location.href = "#/country/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.countryForm.disable();
          } else {
            window.location.href = "#/country/list";
            return;
          }
        } else {
          return;
        }
        this.country = this.data.storage.country;
      }
    });
  }

  countryForm = this.formBuilder.group({
    name: ['', Validators.required],
    isoDigitCode: ['', Validators.required],
    alphaISO2Code: ['', Validators.required],
    alphaISO3Code: (""),
    code: ['', Validators.maxLength(3)],
    status: (""),
  })

  get f() { return this.countryForm.controls; }


  ngOnInit() { }


  onSubmit() {
    this.submitted = true;
    if (this.countryForm.invalid) {
      return;
    }
    // this.country = this.countryForm.value;
    this.updateCountry();
  }

  updateCountry() {
    this.countryService.updateCountry(this.country).subscribe(res => {
      if (res.payload.code == VHRResponse.SUCCESS) {
        if (this.country.id == null) {
          this.isSuccess = true;
          this.message = "SUCCESS!  Created SuccessFully";
        } else if (this.country.id !== null) {
          this.isSuccess = true;
          this.message = "SUCCESS!  Updated SuccessFully";
        }
        setTimeout(() => {
          this.router.navigate(['country', 'list']);
        }, 1500)
      } else if (res.payload.code === VHRResponse.COUNTRY_ALREADY_EXIST) {
        this.countryForm.controls.name.setErrors({ 'duplicateError': true });
      } else if (res.payload.code === VHRResponse.CODE_ALREADY_EXIST) {
        this.countryForm.controls.isoDigitCode.setErrors({ 'duplicateError': true });
      } else if (res.payload.code == VHRResponse.UNKNOWN_ERROR) {
        this.isError = true;
      } else {
        this.isError = true;
      }
    }, error => { console.log(error) });
  }

}
