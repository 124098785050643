import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from '../../../app/auth/authorization/guard/authorization.guard';
import { LoggedInGuard } from '../../../app/auth/login/guard/login.guard';
import { CreatedVoucherReportComponent } from './component/created-voucher/created-voucher-report.component';
import { PurchasedVoucherReportComponent } from './component/purchased-voucher/purchased-voucher-report.component';
import { TrancationReportComponent } from './component/transcation/transcation-report.component';
import { CreatedVoucherReportResolver } from './resolver/created-voucher.resolver';
import { TranscationReportResolver } from '../reports/resolver/transcation.resolver';
import { TranscationLogReportComponent } from './component/transcation-log/transcation-log-report.component';
import { MerchantReportComponent } from './component/merchant-report/merchant-report.component';
import { MerchantListsResolver } from './resolver/merchants-lists.resolver';


const routes: Routes = [
  {
    path: "created-voucher/report",
    component: CreatedVoucherReportComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
    resolve: {
      data: MerchantListsResolver
    }
  },
  {
    path: "transcation/report",
    component: TrancationReportComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
    resolve: {
      data: TranscationReportResolver
    }
  },
  {
    path: "purchased-voucher/report",
    component: PurchasedVoucherReportComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' },
  },
  {
    path: "transcation-log/report",
    component: TranscationLogReportComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' }
  },
  {
    path: "merchant/report",
    component: MerchantReportComponent,
    canActivate: [LoggedInGuard, AuthorizationGuard],
    data: { permission: 'VHR-MMV' }
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
