import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemConstants } from '../../constant/system/system.constant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../environments/environment';
import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public defaultWarehouseSelected: EventEmitter<any> = new EventEmitter<any>();
  private baseUrl = environment.API_URL;
  private headers = SystemConstants.header;

  constructor(private router: Router, private ngxService: NgxUiLoaderService, private scriptLoaderService: ScriptLoaderService) { }

  logout() {
    this.scriptLoaderService.clearAll();
    localStorage.clear();
    this.router.navigate(['login-page']);
    setTimeout(() => {
      this.ngxService.stopAll();
    }, 100);
  }

  getToken() {
    return localStorage.getItem(SystemConstants.b64EncodeUnicode('auth_token'));
  }

  setToken(token: any) {
    localStorage.setItem(SystemConstants.b64EncodeUnicode('auth_token'), token);
  }

  getRefreshToken() {
    return localStorage.getItem(SystemConstants.b64EncodeUnicode('refresh_token'));
  }

  setRefreshToken(refreshToken: any) {
    localStorage.setItem(SystemConstants.b64EncodeUnicode('refresh_token'), refreshToken);
  }
}
