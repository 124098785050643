import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { RoleService } from "../../../_services/role.service";

@Injectable()
export class RoleDetailResolver implements Resolve<any> {

  constructor(private roleService: RoleService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return forkJoin([this.roleService.getPermissions(), this.roleService.getRoleTypes()]).pipe(map(responses => {
        return {
          permissions: responses[0],
          roleTypes: responses[1]
        };
    }));
  }

}