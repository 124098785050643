import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as _ from 'underscore';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';


declare var $: any;

@Component({
  selector: 'merchant-method-list',
  templateUrl: './paymentMethodList.html',
  styleUrls: [],
  providers: []
})

export class PaymentMethodListComponent implements OnInit {

  private table;
  public paymentMethodList: any;

  constructor(private ngxService: NgxUiLoaderService, private router: Router, private data: Data, public route: ActivatedRoute) {
    this.route.data.subscribe(response => {
      if (response.data.payload.code === VHRResponse.SUCCESS) {
        this.paymentMethodList = response.data.payload.responseData.apmVos;
      }
    });
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.loadTable();
    setTimeout(() => $('select').formSelect(), 50);
  }

  paymentMethodDetail(body: any, mode: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": mode
      }
    }
    this.data.storage = {
      paymentMethod: body,
    };
    this.router.navigate(['payment-method'], navigationExtras)
  }

  loadTable() {
    setTimeout(() => {
      this.table = $("#payment-method-list").DataTable({
        responsive: true,
        "pageLength": 100,
        fixedColumns: true,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 }
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
          }, 50);
        }
      });
    }, 50);
  }

}