import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { CurrencyService } from '../../currency/service/currency.service';
import { MerchantService } from "../../merchant/service/merchant.service";



@Injectable()
export class VoucherDenominationResolver {

    constructor(public currencyService: CurrencyService, public merchantService: MerchantService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requests = [this.merchantService.getActiveMerchants(), this.currencyService.getActiveCurrencies()];
        return forkJoin(requests).pipe(map((responses) => {
            let response = {
                merchant: responses[0],
                currency: responses[1],
            }
            return response;
        }))
    }


}