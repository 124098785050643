import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { NullCheckPipe } from './null-check.pipe';



@NgModule({
  declarations:[NullCheckPipe],
  imports:[CommonModule],
  exports:[NullCheckPipe]
})

export class PipeModule{}
