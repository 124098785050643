import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as _ from 'underscore';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CurrencyService } from '../../service/currency.service';
import { Data } from '../../../../providers/data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';

declare var $: any;

@Component({
  selector: 'currency-list',
  templateUrl: './currencyList.html',
  styleUrls: [],
  providers: [CurrencyService]
})
export class CurrencyListComponent implements OnInit {
  public currencies: any
  private table;


  constructor(private ngxService: NgxUiLoaderService, private currencyService: CurrencyService,
    private router: Router, private data: Data, public route: ActivatedRoute) {
    this.route.data.subscribe(response => {
      if (response.data.payload.code == VHRResponse.SUCCESS) {
        this.currencies = response.data.payload.responseData.currencies;
      }
    });
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.loadTable();
    setTimeout(() => $('select').formSelect(), 50);
  }

  add() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": "Add"
      }
    }
    this.router.navigate(['currency'], navigationExtras);
  }

  viewDetail(currency: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": "View",
      }
    }
    this.data.storage = {
      currency: currency,
    };
    this.router.navigate(['currency'], navigationExtras);
  }

  editDetail(currency: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": "Edit"
      }
    }
    this.data.storage = {
      currency: currency,
    };
    this.router.navigate(['currency'], navigationExtras);
  }

  loadTable() {
    setTimeout(() => {
      this.table = $("#curency-list-table").DataTable({
        responsive: true,
        "pageLength": 10,
        fixedColumns: true,
        columnDefs: [
          { responsivePriority: 1, targets: -1 },
          { responsivePriority: 1, targets: 0 }
        ],
        drawCallback: (settings) => {
          setTimeout(() => {
            $(".tooltip").tooltip();
          }, 50);
        }
      });
    }, 50);
  }

}