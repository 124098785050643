import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as _ from 'underscore';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VHRResponse } from '../../../../util/response/response';


declare var $: any;

@Component({
  selector: 'merchant-banner-list',
  templateUrl: './merchantBannerList.html',
  styleUrls: [],
  providers: []
})
export class MerchantBannerListComponent implements OnInit {

  private table;

  constructor(private ngxService: NgxUiLoaderService, private router: Router, private data: Data, public route: ActivatedRoute) {

  }

  ngOnInit() {  }

  ngAfterViewInit(){
  }

  merchantBanner(body: any, mode:any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "mode": mode
      }
    }
    this.data.storage = {
      banner: body,
    };
    this.router.navigate(['merchant-banner'], navigationExtras)
  }
}