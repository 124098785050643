import { Injectable } from "@angular/core";
import { MerchantVoucherService } from "../service/merchant-voucher.service";



@Injectable()
export class MerchantVoucherListResolver {

    constructor(private service: MerchantVoucherService){}

    resolve(){
        return this.service.getActiveMerchantVouchers();
    }

}