import { Injectable } from "@angular/core";
import {CurrencyService} from '../../currency/service/currency.service';
import { VoucherDenominationService } from "../service/voucher-denomination.service";



@Injectable()
export class VoucherDenominationListResolver {

    constructor(public service : VoucherDenominationService ){}

    resolve(){
        return this.service.getVoucherDenomination();
    }

}