import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response';
import { DomSanitizer } from '@angular/platform-browser';
import { MerchantBannerService } from '../../service/merchant-banner.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'merchant-banner-detail',
  templateUrl: './merchantBanner.html',
  providers: [MerchantBannerService]
})
export class MerchantBannerComponent implements OnInit {

  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;
  submitted = false;
  public merchantList: any;


  constructor(private formBuilder: FormBuilder, private router: Router, private data: Data, private route: ActivatedRoute, 
      private changeDetector: ChangeDetectorRef, private sanitizer: DomSanitizer, private bannerService: MerchantBannerService) {
        this.route.data.subscribe(response => {
          console.log(response);
          this.merchantList = response.data.merchant.responseData.merchants;
        });
	}

  merchantBannerForm = this.formBuilder.group({
    merchantId: [''],
    bannerImageFileName: [''],
    bannerImage: [''],

  });

  get f() { return this.merchantBannerForm.controls; }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      $('select').formSelect();
    }, 100);
  }

  handleBannerImageUpload(files) {
    const reader = new FileReader();
      let filename = files[0].name;
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        let bannerGrp = new FormGroup({
          bannerImageBase64: new FormControl(reader.result),
          filename: new FormControl(filename),
        });
        this.merchantBannerForm.get('bannerImage').setValue(bannerGrp.value.bannerImageBase64);
        this.merchantBannerForm.get('bannerImageFileName').setValue(bannerGrp.value.filename);
      }
  }

  getSanitizedImageBase64() {
    //   let bannerImageUrl = this.merchantForm.controls.bannerImageUrl;
         let bannerImageBase64 = this.merchantBannerForm.get('bannerImage').value;
         bannerImageBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(bannerImageBase64);
        return bannerImageBase64;
    }
  
  openImageInNewTab() {
    let url = this.getSanitizedImageBase64();
    url = url.changingThisBreaksApplicationSecurity;
    let img = '<img src="' + url + '">';
    let w = window.open();
    w.document.write(img);
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.merchantBannerForm.getRawValue());
    if (this.merchantBannerForm.invalid) {
      return;
    }
    console.log(this.merchantBannerForm.getRawValue());
    let body = this.merchantBannerForm.getRawValue()
    this.bannerService.saveBanner(body).subscribe(response => {
      console.log(response);
      
      // if (response.payload.code == "VHR_SUCCESS_00") {
      //   this.isSuccess = true;
        
      //   setTimeout(() => {
      //     this.router.navigate(['merchant-banner', 'list']);
      //   }, 1500)
      // } else {
      //  alert(response.message);
      // }
    });
  }

}
