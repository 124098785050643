export const environment = {
  production: false,

  // API_URL: 'http://myvoucher.io/evs-spring-api/'

  //For Staging
  API_URL: 'https://api.myvoucher.io/evs-spring-api/'

  //For DEV
  // API_URL: "https://myvoucherapi.evantagesoft.com/evs-spring-api/"

  // API_URL: 'http://52.208.171.247:8080/evs-spring-api/'

  // API_URL: 'http://localhost:8082/evs-spring-api/'

  //for local
  // API_URL: 'http://localhost:8080/evs_spring_api_war/'

};
