import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyService } from '../../service/currency.service';
import { Currency } from '../../model/currency';
import { Data } from '../../../../providers/data';
import { VHRResponse } from '../../../../util/response/response';


declare var $: any;

@Component({
  selector: 'currency-detail',
  templateUrl: './currency.html',
  providers: [CurrencyService]
})
export class CurrencyComponent implements OnInit {
  public message: any;
  public isSuccess: boolean = false;
  public isUpdated: boolean = false;
  public isError: boolean = false;

  mode: string = "Add";
  submitted = false;
  currency: Currency = new Currency();

  constructor(private formBuilder: FormBuilder, private router: Router, private currencyService: CurrencyService,
    private data: Data, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (params["mode"] != null) {
        this.mode = params["mode"];
        if (this.mode == "Edit") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.currency = this.data.storage.currency;
          } else {
            window.location.href = "#/currency/list";
            return;
          }
        } else if (this.mode == "View") {
          if ((this.data.storage != undefined || this.data.storage != null)) {
            this.currencyForm.disable();
          } else {
            window.location.href = "#/currency/list";
            return;
          }
        } else {
          return;
        }
        this.currency = this.data.storage.currency;
      }
    });
  }

  ngOnInit() { }

  onSubmit() {
    this.submitted = true;
    if (this.currencyForm.invalid) {
      return;
    }
    // console.log(this.currencyForm.value);
    this.updateCurrency();
  }

  currencyForm = this.formBuilder.group({
    name: ['', Validators.required],
    alphaCode: ['', Validators.required],
    code: ['', Validators.required],
    symbol: [],
    status: []
  })
  get f() { return this.currencyForm.controls; }

  updateCurrency() {
    this.currencyService.updateCurrency(this.currency).subscribe(res => {
      if (res.payload.code == VHRResponse.SUCCESS) {
        if (this.currency.id == null) {
          this.isSuccess = true;
          this.message = "SUCCESS!  Created SuccessFully";
        } else if (this.currency.id !== null) {
          this.isSuccess = true;
          this.message = "SUCCESS!  Updated SuccessFully";
        }
        setTimeout(() => {
          this.router.navigate(['currency', 'list']);
        }, 1500)
      }
      else if (res.payload.code === "CURRENCY_ALREADY_EXIST") {
        this.currencyForm.controls.name.setErrors({ 'duplicateError': true });
      } else if (res.payload.code === VHRResponse.CODE_ALREADY_EXIST) {
        this.currencyForm.controls.alphaCode.setErrors({ 'duplicateError': true });
      } else if (res.payload.code == VHRResponse.UNKNOWN_ERROR) {
        this.isError = true;
      } else {
        this.isError = true;
      }
    }, error => { console.log(error) });
  }
}
