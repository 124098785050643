import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../auth/auth-token/authorization.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authorizationService: AuthorizationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authorizationService.getToken();
    const authReq = request.clone({
      setHeaders: {
        Authorization: `VHR ${token}`,
        'Content-Type': 'application/json'
      }
    });

    // console.log('AuthInterceptor: Added Authorization header', authReq);
    return next.handle(authReq);
  }

}
